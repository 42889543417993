export const PRIJEMROBE_V1ResourcesBS: PRIJEMROBE_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errorMissingInput: "Molimo popunite sva polja",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  input: {
    signatureTitle: "Potpis",
    datum: {
      label: "Datum",
      placeholder: "Molimo unesite datum",
      name: "datum",
    },
    ispravnaPosiljka: {
      label: "Da li je neispravna posiljka?",
      placeholder: "Molimo unesite da li je neispravna posiljka",
      name: "ispravnaPosiljka",
    },
    dokument: {
      label: "Dokumenti",
      placeholder: "Molimo unesite dokumente",
      name: "dokument",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
    },
    korektivnaMjera: {
      label: "Korektivna mjera",
      placeholder: "Molimo unesite korektivnu mijeru",
      name: "korektivnaMjera",
    },
    potpisPrimalac: {
      label: "Molimo potpisite dostavu",
      placeholder: "",
      name: "potpisano",
    },
    primalac: {
      label: "Dostavljač",
      placeholder: "Molimo unesite ime dostavljača / naziv firme",
      name: "primalac",
    },
    primjedba: {
      label: "Primjedba",
      placeholder: "Molimo unesite primjedbu",
      name: "primjedba",
    },
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface InputField {
  label: string;
  placeholder: string;
  name: string;
  description?: () => JSX.Element; // Optional, as not all fields have it
}

interface Input {
  signatureTitle: string;
  datum: InputField;
  ispravnaPosiljka: InputField;
  dokument: InputField;
  korektivnaMjera: InputField;
  potpisPrimalac: InputField;
  primalac: InputField;
  primjedba: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IErrors {
  registerPic: string;
}

export interface PRIJEMROBE_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  errors: IErrors;
}
