export const DEZSR_V1ResourcesBS: DEZSR_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  input: {
    naziv: {
      label: "Naziv *",
      key: "naziv",
      placeholder: "Molimo unesite naziv",
      error: "Molimo unesite naziv",
    },
    primjena: {
      label: "Primjena",
      key: "primjena",
      placeholder: "Molimo unesite primjenu",
    },
    upustvoZaUpotrebu: {
      label: "Upustvo za upotrebu",
      key: "upustvoZaUpotrebu",
      placeholder: "Molimo unesite na koji nacin se primjenjuje",
    },
    vrsta: {
      label: "Vrsta",
      key: "vrsta",
      placeholder: "Vrsta sredstva prema deklaraciji proizvodjaca",
    },
    slika: {
      label: "Slika",
      name: "slika",
      error: "Please select your picture",
      validation: "Wrong picture",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
      key: "",
      placeholder: "",
    },
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
  errorMissingInput: "Molimo popunite sva polja",
};

interface InputField {
  label: string;
  key: string;
  placeholder: string;
  name?: string;
  error?: string; // Optional since not all fields have it
  validation?: string; // Optional since not all fields have it
  description?: () => JSX.Element; // Optional since not all fields have it
}

interface Input {
  naziv: InputField;
  primjena: InputField;
  upustvoZaUpotrebu: InputField;
  vrsta: InputField;
  slika: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IErrors {
  registerPic: string;
}

export interface DEZSR_V1ResourcesBSInterface {
  labelDone: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  errorMissingInput: string;
  errors: IErrors;
}
