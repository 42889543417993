export const ListaRadnogVremenaResourcesBS: ListaRadnogVremenaResourcesInterface =
  {
    title: "Lista Radnog Vremena",
    errors: {
      query:
        "Doslo je do greske tokom loadiranja korisnika. Molimo kontaktirajte administratora.",
    },
    inputFields: {
      endDate: {
        label: "Krajnji datum",
      },
      startDate: {
        label: "Pocetni datum",
      },
      user: {
        error: "Molimo odaberite korisnika",
        label: "Odabir korisnika",
        name: "user",
        placeholder: "Odaberite korisnika",
      },
    },
    pdf: {
      button: "Download PDF",
      document: {
        ukupnoVrijeme: "Ukupno sati",
        name: "Ime i prezime",
        title: "Evidencija radnog vremena",
        odabraniPeriod: "Odabrani period",
        potpisFooter: "Potpis",
        upisaoFooter: "Upisao",
        entryType: "Tip evidencije",
        napomena: "Napomena",
        pocetak: "Pocetak",
        pocetakPauze: "Pocetak pauze",
        potpisano: "Potpis",
        zavrsetak: "Zavrsetak",
        zavrsetakPauze: "Zavrsetak pauze",
        tipovi: {
          DEZURA: "Dezura",
          ODSUSTVO: "Odsustvo",
          PRIPRAVNOST: "Pripravnost",
          RADNOVRIJEME: "Radno vrijeme",
          RADUSMJENAMA: "Rad u smjenama",
          TERENSKIRAD: "Terenski rad",
        },
      },
    },
  };

interface InputFieldInterface {
  label: string;
  name: string;
  placeholder: string;
  error: string;
}

export interface RadnoVrijemePDFInterface {
  name: string;
  title: string;
  ukupnoVrijeme: string;
  odabraniPeriod: string;
  potpisFooter: string;
  upisaoFooter: string;
  entryType: string;
  pocetak: string;
  zavrsetak: string;
  pocetakPauze: string;
  zavrsetakPauze: string;
  napomena: string;
  potpisano: string;
  tipovi: {
    RADNOVRIJEME: string;
    RADUSMJENAMA: string;
    TERENSKIRAD: string;
    DEZURA: string;
    PRIPRAVNOST: string;
    ODSUSTVO: string;
  };
}

export interface ListaRadnogVremenaResourcesInterface {
  title: string;
  errors: {
    query: string;
  };
  inputFields: {
    startDate: {
      label: string;
    };
    endDate: {
      label: string;
    };
    user: InputFieldInterface;
  };
  pdf: {
    button: string;
    document: RadnoVrijemePDFInterface;
  };
}
