export const CIPO_V1ResourcesBS: CIPO_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  type: {
    EvdLabel: "Napomena",
    Name: "type",
    Placeholder:
      "Upisuje se: Redovno, vanredno, generalno, sedmicno, mjesecno, godisnje...",
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface Type {
  EvdLabel: string;
  Name: string;
  Placeholder: string;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

export interface CIPO_V1ResourcesBSInterface {
  labelDone: string;
  type: Type;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
}
