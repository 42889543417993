export const DOBAVLJAC_V1ResourcesBS: DOBAVLJAC_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
  input: {
    street: {
      label: "Ulica i broj",
      name: "street",
      error: "Niste unijeli street",
    },
    city: {
      label: "Grad",
      name: "city",
      error: "Niste unijeli grad",
    },
    zipCode: {
      label: "Poštanski broj",
      name: "zipCode",
      error: "Niste unijeli poštanski broj",
      validation: "Neispravan broj",
    },
    country: {
      label: "Država",
      name: "country",
      error: "Niste unijeli državu",
    },
    phone: {
      label: "Telefon",
      name: "phoneNumber",
      error: "Niste unijeli telefon",
      validation: "Neispravan broj",
    },
    idCompanyNumber: {
      label: "ID broj",
      name: "idTax",
      error: "Niste unijeli ID broj",
      validation: "Neispravan broj",
    },
    vatCompanyNumber: {
      label: "PDV broj",
      name: "vatCompanyNumber",
      error: "Niste unijeli PDV broj",
      validation: "Neispravan broj",
    },
    name: {
      label: "Naziv dobavljača",
      name: "name",
      placeholder: "Molimo unesite naziv dobavljača",
      error: "",
    },
    productType: {
      label: "Tip produkta",
      name: "productType",
      placeholder: "Molimo unesite tip produkta",
      error: "",
    },
    dialogSuccess: {
      title: "Uspješno!",
      body: "Dodali ste kompaniju u bazu podataka!",
      buttonOk: "OK",
    },
  },
};

interface InputField {
  label: string;
  name: string;
  error: string;
  validation?: string; // Optional, not all fields may have validation
  placeholder?: string; // Optional, not all fields may have placeholders
}

interface DialogSuccess {
  title: string;
  body: string;
  buttonOk: string;
}

interface Input {
  street: InputField;
  city: InputField;
  zipCode: InputField;
  country: InputField;
  phone: InputField;
  idCompanyNumber: InputField;
  vatCompanyNumber: InputField;
  name: InputField;
  productType: InputField;
  dialogSuccess: DialogSuccess;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

export interface DOBAVLJAC_V1ResourcesBSInterface {
  labelDone: string;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  input: Input;
}
