export const SAN_V1ResourcesBS: SAN_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errorMissingInput: "Molimo popunite sva polja",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  input: {
    signatureTitle: "Potpis",
    datum: {
      label: "Datum",
      placeholder: "Molimo unesite datum",
      name: "datum",
    },
    dokument: {
      label: "Dokument",
      placeholder: "Molimo unesite dokument",
      name: "dokument",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
    },
    vaziOd: {
      label: "Vazi od",
      name: "vaziOd",
    },
    vaziDo: {
      label: "Vazi do",
      name: "vaziDo",
    },
    uposlenik: {
      label: "Uposlenik",
      name: "uposlenik",
      placeholder: "Molimo unesite uposlenikovo ime",
    },
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface InputField {
  label: string;
  name: string;
  placeholder?: string; // Optional, as not all fields have it
  description?: () => JSX.Element; // Optional, as not all fields have it
}

interface Input {
  signatureTitle: string;
  datum: InputField;
  dokument: InputField;
  vaziOd: InputField;
  vaziDo: InputField;
  uposlenik: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IErrors {
  registerPic: string;
}

export interface SAN_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  errors: IErrors;
}
