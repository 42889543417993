export const TEMPERATURAKKTPROIZVODI_V1ResourcesBS: TEMPERATURAKKTPROIZVODI_V1ResourcesBSInterface =
  {
    labelDone: "Datum obavljanja zadatka",
    errorMissingInput: "Molimo popunite sva polja",
    input: {
      signatureTitle: "Potpis",
      checkbox: {
        label: "Potvrdjujem da sam obavjesten da temperatura nije odgovarajuca",
        name: "checkbox",
        placeholder: "Morate prihvatiti polje",
      },
      proizvod: {
        label: "Proizvod",
        placeholder: "Molimo unesite naziv proizvoda",
        name: "proizvod",
        error: "Naziv proizvoda nije unesen",
      },

      datum: {
        label: "Molimo unesite datum",
        placeholder: "Molimo unesite datum",
        name: "datum",
      },
      ocitanaTemperatura: {
        label: "Ocitana temperatura",
        name: "ocitanaTemperatura",
        placeholder: "Molimo unesite ocitanu temperaturu",
      },
      napomena: {
        label: "Napomena",
        name: "napomena",
        placeholder: "Molimo unesite napomenu",
      },
      korektivnaMjera: {
        label: "Korektivna Mjera",
        name: "korektivnaMjera",
        placeholder: "Molimo odaberite da li je korektivua mjera potrebna",
      },
    },
    evidencijaForm: {
      buttonForward: "Potpisi",
      buttonBack: "Nazad",
    },
    successModal: {
      title: "Unos podataka",
      body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
      buttonOk: "OK",
    },
    txtPotvrda:
      "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
    dropdownKorektivnaMjera: {
      da: "Da",
      ne: "Ne",
      undefined: "Molimo odaberite polje",
    },
  };

interface InputField {
  label: string;
  name: string; // Optional since some fields like 'stanje' and 'potpisIzvrsiocaKorekcije' don't have a 'name'
  placeholder?: string; // Optional since not all fields have it
  error?: string;
}

interface Input {
  signatureTitle: string;
  datum: InputField;
  ocitanaTemperatura: InputField;
  napomena: InputField;
  checkbox: InputField;
  korektivnaMjera: InputField;
  proizvod: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IDropdownOptions {
  da: string;
  ne: string;
  undefined: string;
}

export interface TEMPERATURAKKTPROIZVODI_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  dropdownKorektivnaMjera: IDropdownOptions;
}
