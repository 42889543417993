export const DEZINSEKCIJA_V1_ResourcesBS: DEZINSEKCIJA_V1_ResourcesBSInterface =
  {
    labelDone: "Datum obavljanja zadatka",
    evidencijaForm: {
      buttonForward: "Potpisi",
      buttonBack: "Nazad",
    },
    errors: {
      registerPic: "The picture you are uploading is too large.",
    },
    successModal: {
      title: "Unos podataka",
      body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
      buttonOk: "OK",
    },
    txtPotvrda:
      "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
    profilePicture: {
      label: " Slika deklaracije",
      name: "profilePicture",
      error: "Please select your profile picture",
      validation: "Wrong profile picture",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
    },
    errorMissingInput: "Molimo popunite sva polja",
    kontrola: {
      checkboxLabel: "Infestacija insektima",
      opisKontrole: "Opis kontrole",
      errorOpisKontrole: "Opis kontrole je obavezan",
    },
    korekcija: {
      stanjeKorekcije: "Stanje korekcije",
      opisKorekcije: "Opis korekcije",
    },
  };

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface ProfilePicture {
  label: string;
  name: string;
  error: string;
  validation: string;
  description: () => JSX.Element;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface Kontrola {
  checkboxLabel: string;
  opisKontrole: string;
  errorOpisKontrole: string;
}

interface Korekcija {
  stanjeKorekcije: string;
  opisKorekcije: string;
}

interface IErrors {
  registerPic: string;
}

export interface DEZINSEKCIJA_V1_ResourcesBSInterface {
  labelDone: string;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  profilePicture: ProfilePicture;
  errorMissingInput: string;
  kontrola: Kontrola;
  korekcija: Korekcija;
  errors: IErrors;
}
