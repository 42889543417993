export const EvidencijeResourcesBS: EvidencijeResourcesBSInterface = {
  odabirPoljaEvidencije: {
    title: "Odabir Polja Evidencije",
    buttonForward: "Dodaj",
    buttonBack: "Nazad",
    dropdownPlaceholder: "Odaberite polje evidencije",
    successModal: {
      title: "Unos podataka",
      body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
      buttonOk: "OK",
    },
    poljaEvidencije: {
      CIPP_V1: "Ciscenje i pranje prostora v1",
      CIPO_V1: "Ciscenje i pranje opreme v1",
      Dezinfekcija_V1: "Dezinfekcija v1",
      Dezinsekcija_V1: "Dezinsekcija v1",
      Dezinsekcija_V1_Kontrola: "Dezinsekcija kontrola v1",
      Dezinsekcija_V1_Korekcija: "Dezinsekcija korekcija v1",
      Deratizacija_V1: "Deratizacija v1",
      Deratizacija_V1_Kontrola: "Deratizacija kontrola v1",
      Deratizacija_V1_Korekcija: "Deratizacija korekcija v1",
      DezSr_V1: "Sredstava za dezinfekciju v1",
      Dobavljac_V1: "Dobavljaci v1",
      Dostava_V1: "Dostava / Isporuka Robe v1",
      EduZap_V1: "Edukacija zaposlenih v1",
      Mol_V1: "Molerski radovi v1",
      Oprema_V1: "Oprema v1",
      Otpad_V1: "Otpad v1",
      PrijemRobe_V1: "Prijem robe v1",
      San_V1: "Sanitarni pregled v1",
      Temperatura_V1: "Temperaturne liste v1",
      TemperaturaOgranicenaEvd_V1: "Temperaturne liste - ogranicena v1",
      TemperaturaOgranicenaKKTEvd_V1:
        "Temperaturne liste - ogranicena - KKT - v1",
      TemperaturaKKTProizvodiEvd_V1: "Temperaturne liste - proizvodi - KKT v1",
    },
  },
  evidencije: {
    tackePrimjene: (count: number) => <>Tacke primjene: Odabrana/o: {count}</>,
    ucestalost: (type: string, count: number) => (
      <>
        Ucestalost: Odabrano {type} ({count})
      </>
    ),
    odgovorneOsobe: (count: number) => (
      <>Odgovorne Osobe: Odabrana kategorija ({count})</>
    ),
    poljaEvidencije: (polja: string) => {
      return <>{`Polja evidencije: ${polja}`}</>;
    },
    nextButton: "Ispuni evidenciju",
    backButton: "Nazad",
  },
};

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface PoljaEvidencije {
  CIPP_V1: string;
  CIPO_V1: string;
  Dezinfekcija_V1: string;
  Dezinsekcija_V1: string;
  Dezinsekcija_V1_Kontrola: string;
  Dezinsekcija_V1_Korekcija: string;
  Deratizacija_V1: string;
  Deratizacija_V1_Kontrola: string;
  Deratizacija_V1_Korekcija: string;
  DezSr_V1: string;
  Dobavljac_V1: string;
  Dostava_V1: string;
  EduZap_V1: string;
  Mol_V1: string;
  Oprema_V1: string;
  Otpad_V1: string;
  PrijemRobe_V1: string;
  San_V1: string;
  Temperatura_V1: string;
  TemperaturaOgranicenaEvd_V1: string;
  TemperaturaOgranicenaKKTEvd_V1: string;
  TemperaturaKKTProizvodiEvd_V1: string;
  // Add more fields as necessary
}

interface OdabirPoljaEvidencije {
  title: string;
  buttonForward: string;
  buttonBack: string;
  dropdownPlaceholder: string;
  successModal: SuccessModal;
  poljaEvidencije: PoljaEvidencije;
}

interface Evidencije {
  tackePrimjene: (count: number) => JSX.Element;
  ucestalost: (type: string, count: number) => JSX.Element;
  odgovorneOsobe: (count: number) => JSX.Element;
  poljaEvidencije: (polja: string) => JSX.Element;
  nextButton: string;
  backButton: string;
}

export interface EvidencijeResourcesBSInterface {
  odabirPoljaEvidencije: OdabirPoljaEvidencije;
  evidencije: Evidencije;
}
