export const RadnoVrijemeSumEvdResourceBS: RadnoVrijemeSumEvdInterface = {
  title: "Lista Radnog Vremena",
  errors: {
    query:
      "Doslo je do greske tokom loadiranja korisnika. Molimo kontaktirajte administratora.",
  },
  inputFields: {
    branch: {
      placeholder: "Odaberite poslovnicu",
      notFound: "Ni jedna poslovnica nije pronadjena",
    },
    endDate: {
      label: "Krajnji datum",
    },
    startDate: {
      label: "Pocetni datum",
    },
    user: {
      error: "Molimo odaberite korisnika",
      label: "Odabir korisnika",
      name: "user",
      placeholder: "Odaberite korisnika",
    },
  },
  pdf: {
    button: "Download PDF",
    document: {
      name: "Ime i prezime",
      title: "Evidencija radnog vremena",
      odabraniPeriod: "Odabrani period",
      ukupnoVrijeme: "Ukupno sati",
      potpisFooter: "Potpis",
      upisaoFooter: "Upisao",
      entryType: "Tip evidencije",
      napomena: "Napomena",
      pocetak: "Pocetak",
      pocetakPauze: "Pocetak pauze",
      potpisano: "Potpis",
      zavrsetak: "Zavrsetak",
      zavrsetakPauze: "Zavrsetak pauze",
      tipovi: {
        DEZURA: "Dezura",
        ODSUSTVO: "Odsustvo",
        PRIPRAVNOST: "Pripravnost",
        RADNOVRIJEME: "Radno vrijeme",
        RADUSMJENAMA: "Rad u smjenama",
        TERENSKIRAD: "Terenski rad",
      },
    },
  },
};

interface InputFieldInterface {
  label: string;
  name: string;
  placeholder: string;
  error: string;
}

export interface RadnoVrijemePDFSumInterface {
  name: string;
  title: string;
  odabraniPeriod: string;
  potpisFooter: string;
  upisaoFooter: string;
  entryType: string;
  pocetak: string;
  zavrsetak: string;
  pocetakPauze: string;
  zavrsetakPauze: string;
  napomena: string;
  potpisano: string;
  ukupnoVrijeme: string;
  tipovi: {
    RADNOVRIJEME: string;
    RADUSMJENAMA: string;
    TERENSKIRAD: string;
    DEZURA: string;
    PRIPRAVNOST: string;
    ODSUSTVO: string;
  };
}

export interface RadnoVrijemeSumEvdInterface {
  title: string;
  errors: {
    query: string;
  };
  inputFields: {
    branch: {
      placeholder: string;
      notFound: string;
    };
    startDate: {
      label: string;
    };
    endDate: {
      label: string;
    };
    user: InputFieldInterface;
  };
  pdf: {
    button: string;
    document: RadnoVrijemePDFSumInterface;
  };
}
