export const RadnoVrijemeEvdResourceBS: RadnoVrijemeEvdInterface = {
  title: "Evidendcija radnog vremena",
  nextButton: "Save evidencije",
  description:
    "Molimo odaberite period u kojem se nalaze evidencije radnog vremena.",
  inputFields: {
    endDate: {
      label: "Krajnji datum",
    },
    startDate: {
      label: "Pocetni datum",
    },
    entryType: {
      error: "Molimo odaberite tip evidencije radnog vremena.",
      label: "Tip evidencije radnog vremena",
      name: "entryType",
      placeholder: "Odaberite tip evidencije radnog vremena",
    },
    napomena: {
      error: "Molimo unesite napomenu.",
      label: "Napomena",
      name: "napomena",
      placeholder: "Po potrebi unesite napomenu",
    },
    pocetak: {
      error: "Molimo unesite pocetak radnog vremena.",
      label: "Pocetak radnog vremena",
      name: "pocetak",
      placeholder: "Molimo unesite pocetak radnog vremena",
    },
    zavrsetak: {
      error: "Molimo unesite zavrsetak radnog vremena.",
      label: "Zavrsetak radnog vremena",
      name: "zavrsetak",
      placeholder: "Molimo unesite zavrsetak radnog vremena",
    },
    pocetakPauze: {
      error: "Molimo unesite pocetak pauze.",
      label: "Pocetak pauze",
      name: "pocetakPauze",
      placeholder: "Molimo unesite pocetak pauze",
    },
    zavrsetakPauze: {
      error: "Molimo unesite zavrsetak pauze.",
      label: "Zavrsetak pauze",
      name: "zavrsetakPauze",
      placeholder: "Molimo unesite zavrsetak pauze",
    },
  },
  errors: {
    errorDeleting:
      "Doslo je do greske tokom brisanja evidencije radnog vremena. Molimo kontaktirajte administratora.",
    errorLoading:
      "Doslo je do greske tokom loadiranja evidencije radnog vremena. Molimo kontaktirajte administratora.",
    errorUpdating:
      "Doslo je do greske tokom spasavanja evidencije radnog vremena. Molimo kontaktirajte administratora.",
    errorMissingFields:
      "Molimo popunite obavezna polja - tip radnog vremena, pocetak radnog vremena i zavrsetak radnog vremena.",
  },
  types: {
    placeholder: "Molimo odaberite tip radnog vremena",
    radnovrijeme: "Radno vrijeme",
    dezura: "Dezura",
    odsustvo: "Odsustvo",
    pripravnost: "Pripravnost",
    radusmjenama: "Rad u smjenama",
    terenskirad: "Terenski rad",
  },
  successModal: {
    title: "Adding to DB Success",
    body: "Adding to DB has been successfull, please move forward to edit / update.",
    buttonOk: "OK",
  },
};

interface InputFieldInterface {
  label: string;
  name: string;
  placeholder: string;
  error: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

export interface RadnoVrijemeEvdInterface {
  successModal: SuccessModal;
  title: string;
  description: string;
  nextButton: string;
  inputFields: {
    startDate: {
      label: string;
    };
    endDate: {
      label: string;
    };
    entryType: InputFieldInterface;
    pocetak: InputFieldInterface;
    zavrsetak: InputFieldInterface;
    napomena: InputFieldInterface;
    pocetakPauze: InputFieldInterface;
    zavrsetakPauze: InputFieldInterface;
  };
  errors: {
    errorLoading: string;
    errorUpdating: string;
    errorDeleting: string;
    errorMissingFields: string;
  };
  types: {
    placeholder: string;
    radnovrijeme: string;
    dezura: string;
    odsustvo: string;
    pripravnost: string;
    radusmjenama: string;
    terenskirad: string;
  };
}
