export const MenuItemsResourcesBS: MenuItemsResourcesInterface = {
  mainMenuItems: {
    home: {
      title: "Home",
      subMenuItems: {
        planiranizadaci: "Planirani zadaci",
        evidencijapopotrebi: "Evidencija po potrebi",
        prijavaodjava: "Radno vrijeme",
      },
    },
    users: {
      title: "Korisnici",
      subMenuItems: {
        dodavanjeKorisnika: "Dodavanje Korisnika",
        upravljanjeKorisnicima: "Upravljanje Korisnicima",
      },
    },
    kompanija: {
      title: "Kompanija",
      subMenuItems: "",
    },
    evidencija: {
      title: "Evidencija",
      subMenuItems: "",
    },
    statistika: {
      title: "Statistika",
      subMenuItems: "",
    },
    radnovrijeme: {
      title: "Radno vrijeme",
      subMenuItems: "",
    },
    pohvaleikritike: {
      title: "Pohvale i kritike",
      subMenuItems: "",
    },
    administracija: {
      title: "Administracija",
      subMenuItems: {
        recordPoint: "Arhitektura",
        register: "Kompanije",
        users: "Korisnici",
        recordsSettings: "Katalog evidencija",
        qrCodes: "QR Kodovi",
      },
    },
    lists: {
      title: "Liste Kompanija & Evidencija", 
      listaKompanija: "Kompanije",
      listaEvidencija: "Evidencije",
      listaRadnogVremena: "Radno vrijeme",
        listaRadnogVremenaSum: "Zbirna lista vremena", 
    },
    footer: {
      title: "O nama",
      submenuItems: {
        aboutus: "O nama",
      },
    },
  },
};

export const MenuItemsResourcesEN: MenuItemsResourcesInterface = {
  mainMenuItems: {
    home: {
      title: "Home",
      subMenuItems: {
        planiranizadaci: "Scheduled assignments",
        evidencijapopotrebi: "Assignments as needed",
        prijavaodjava: "Check-in / Check-out",
      },
    },
    users: {
      title: "Korisnici",
      subMenuItems: {
        dodavanjeKorisnika: "Dodavanje Korisnika",
        upravljanjeKorisnicima: "Upravljanje Korisnicima",
      },
    },
    kompanija: {
      title: "Kompanija",
      subMenuItems: "",
    },
    evidencija: {
      title: "Evidencija",
      subMenuItems: "",
    },
    statistika: {
      title: "Statistika",
      subMenuItems: "",
    },
    radnovrijeme: {
      title: "Radno vrijeme",
      subMenuItems: "",
    },
    pohvaleikritike: {
      title: "Pohvale i kritike",
      subMenuItems: "",
    },
    administracija: {
      title: "Administracija",
      subMenuItems: {
        recordPoint: "Arhitektura",
        register: "Kompanije",
        users: "Korisnici",
        recordsSettings: "Katalog evidencija",
        qrCodes: "QR Kodovi",
      },
    },
    lists: {
      title: "Liste Kompanija & Evidencija",
      listaKompanija: "Lista kompanija",
      listaEvidencija: "Lista evidencija",
      listaRadnogVremena: "Lista radnog vremena",
      listaRadnogVremenaSum: "Zbirna lista vremena",
    },
    footer: {
      title: "About Us",
      submenuItems: {
        aboutus: "About Us",
      },
    },
  },
};

export const MenuItemsResourcesDE: MenuItemsResourcesInterface = {
  mainMenuItems: {
    home: {
      title: "Home",
      subMenuItems: {
        planiranizadaci: "Scheduled assignments",
        evidencijapopotrebi: "Assignments as needed",
        prijavaodjava: "Check-in / Check-out",
      },
    },
    users: {
      title: "Korisnici",
      subMenuItems: {
        dodavanjeKorisnika: "Dodavanje Korisnika",
        upravljanjeKorisnicima: "Upravljanje Korisnicima",
      },
    },
    kompanija: {
      title: "Kompanija",
      subMenuItems: "",
    },
    evidencija: {
      title: "Evidencija",
      subMenuItems: "",
    },
    statistika: {
      title: "Statistika",
      subMenuItems: "",
    },
    radnovrijeme: {
      title: "Radno vrijeme",
      subMenuItems: "",
    },
    pohvaleikritike: {
      title: "Pohvale i kritike",
      subMenuItems: "",
    },
    administracija: {
      title: "Administracija",
      subMenuItems: {
        recordPoint: "Arhitektura",
        register: "Kompanije",
        users: "Korisnici",
        recordsSettings: "Katalog evidencija",
        qrCodes: "QR Kodovi",
      },
    },
    lists: {
      title: "Liste Kompanija & Evidencija",
      listaKompanija: "Lista kompanija",
      listaEvidencija: "Lista evidencija",
      listaRadnogVremena: "Lista radnog vremena",
      listaRadnogVremenaSum: "Zbirna lista vremena",
    },
    footer: {
      title: "Über uns",
      submenuItems: {
        aboutus: "Über uns",
      },
    },
  },
};

export interface MenuItemsResourcesInterface {
  mainMenuItems: {
    home: {
      title: string;
      subMenuItems: {
        planiranizadaci: string;
        evidencijapopotrebi: string;
        prijavaodjava: string;
      };
    };
    users: {
      title: string;
      subMenuItems: {
        dodavanjeKorisnika: string;
        upravljanjeKorisnicima: string;
      };
    };
    kompanija: {
      title: string;
      subMenuItems: string; // You may want to change this to an object with sub-menu items
    };
    evidencija: {
      title: string;
      subMenuItems: string; // You may want to change this to an object with sub-menu items
    };
    statistika: {
      title: string;
      subMenuItems: string; // You may want to change this to an object with sub-menu items
    };
    radnovrijeme: {
      title: string;
      subMenuItems: string; // You may want to change this to an object with sub-menu items
    };
    pohvaleikritike: {
      title: string;
      subMenuItems: string; // You may want to change this to an object with sub-menu items
    };
    administracija: {
      title: string;
      subMenuItems: {
        recordPoint: string;
        register: string;
        users: string;
        recordsSettings: string;
        qrCodes: string;
      };
    };
    lists: {
      title: string;
      listaKompanija: string;
      listaEvidencija: string;
      listaRadnogVremena: string;
      listaRadnogVremenaSum: string;
    };
    footer: {
      title: string;
      submenuItems: {
        aboutus: string;
      };
    };
  };
}
