export const DEZINFEKCIJA_V1_ResourcesBS: DEZINFEKCIJA_V1_ResourcesBSInterface =
  {
    labelDone: "Datum obavljanja zadatka",
    evidencijaForm: {
      buttonForward: "Potpisi",
      buttonBack: "Nazad",
    },
    errors: {
      registerPic: "The picture you are uploading is too large.",
    },
    successModal: {
      title: "Unos podataka",
      body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
      buttonOk: "OK",
    },
    txtPotvrda:
      "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
    profilePicture: {
      label: "Slika deklaracije",
      name: "profilePicture",
      error: "Please select your profile picture",
      validation: "Wrong profile picture",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
    },
    errorMissingInput: "Molimo popunite sva polja",
  };

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface ProfilePicture {
  label: string;
  name: string;
  error: string;
  validation: string;
  description: () => JSX.Element;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface IErrors {
  registerPic: string;
}

export interface DEZINFEKCIJA_V1_ResourcesBSInterface {
  labelDone: string;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  profilePicture: ProfilePicture;
  errorMissingInput: string;
  errors: IErrors;
}
