import { Navigate, Route, RouteProps } from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const api = new APICore();

  // 4. Navigate to next page or to login
  // 6. Check depending on user type - what can the user see

  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (api.isUserAuthenticated() === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate
              // state={from: props['path']}
              to={{
                pathname: "/auth/login",
                // state: { from: props['path'] },
              }}
            />
          );
        }

        const loggedInUser = api.getLoggedInUser();
        console.log("The User: ", loggedInUser);
        // TODO: Adjust roles permissions accordingly to our scheme
        // check if route is restricted by role
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
          // role not authorised so redirect to login page
          return <Navigate to={{ pathname: "/" }} />;
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
