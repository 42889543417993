import React from "react";

export const authBS: AuthResourcesInterface = {
  title: "Some Title",
  aboutMeDescription: () => <React.Fragment>Some Element</React.Fragment>,
  registerButton: "Register",
  errors: {
    loginError:
      "Tokom logina je doslo do greske. Provjerite da li su ispravni E-Mail i lozinka. Ako problem i dalje ostane, kontaktirajte administratora.",
    register:
      "Account creation failed. The email you entered was already registered or there was an other issue. Please contact the administrator.",
    registerPic: "The picture you are uploading is too large.",
    emailNotFound: "Your account was not found",
    emailFound:
      "Molimo provjerite Vaš email i unesite jednokratnu šifru koju ste primili.",
    errorPsw:
      "There has been an error updating your password. Please contact the administrator.",
    errorCaptcha:
      "Doslo je do greske tokom provjere. Molimo pokrenite stranicu ponovo. Ako se greska ponovi, kontaktirajte administratora.",
  },
  login: {
    title: "Unesite email adresu i šifru za pristup sistemu evidencije",
    email: "Email",
    emailError: "Molimo unesite ispravnu E-Mail adresu",
    passwordError: "Molimo unesite ispravnu šifru",
    password: "Šifra",
    button: "Pristup",
    emailPlaceholder: "Unesite E-Mail adresu",
    passwordPlaceholder: "Unesite šifru",
    bottom: {
      forgot: "Zaboravili ste šifru?",
      signup: "Registrujte se",
      donthave: "Nemate račun?",
    },
  },
  confirm: {
    title: "Uspješno!",
    email: "Email je poslat na ",
    checkEmail:
      ". Molimo provjerite email koji ste dobili i kliknite na link unutar poruke za resetovanje šifre.",
    back: "Povratak na prijavu",
  },
  forgetPassword: {
    modal: {
      title: "Uspješno",
      text: "Uspješno ste izmjenili podatke.",
      button: "Završeno",
    },
    checkTempButton: "Pohrani novu šifru",
    email: {
      label: "Email",
      name: "email",
      error: "Unesite ispravan email",
      validation: "Pogrešan email",
      placeholder: "Unesite ispravan email",
    },
    tempPassword: {
      label: "Jednokratna šifra",
      name: "tempPassword",
      error: "Molimo upišite Vašu jednokratnu šifru.",
      placeholder: "Unesite Vašu jednokratnu šifru.",
    },
    password: {
      label: "Šifra",
      name: "password",
      error: "Unesite ispravnu šifru",
      validation: "Pogrešna šifra",
    },
    passwordRepeat: {
      label: "Ponovite šifru",
      name: "repeatPassword",
      error: "Molimo ponovite unos šifre",
      validation: "Pogrešna šifra",
      matchError: "Vaša šifra nije ispravna",
    },
    title:
      "Upišite Vašu e-mail adresu na koji ćemo Vam poslati instrukcije za promjenu šifre.",
    changeButton: "Resetuj šifru",
    findButton: "Pronađi korisnika",
    login: {
      description: "Sjetili ste se Vaše šifre?",
      button: "Prijavite se!",
    },
    register: {
      description: "Nemate račun?",
      button: "Registrujte se!",
    },
  },
  logout: {
    back: "Nazad na",
    backButton: "Prijavu",
    title: "Vidimo se opet!",
    description: "Uspješno ste se odjavili!",
  },
};

interface FieldValidation {
  label: string;
  name: string;
  error: string;
  validation?: string;
  placeholder?: string;
  matchError?: string;
}

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface ForgetPassword {
  title: string;
  email: FieldValidation;
  password: FieldValidation;
  passwordRepeat: FieldValidation;
  tempPassword: FieldValidation;
  findButton: string;
  checkTempButton: string;
  changeButton: string;
  login: {
    description: string;
    button: string;
  };
  register: {
    description: string;
    button: string;
  };
  modal: Modal;
}

interface ErrorFields {
  loginError: string;
  register: string;
  registerPic: string;
  emailNotFound: string;
  emailFound: string;
  errorPsw: string;
  errorCaptcha: string;
}

export interface AuthResourcesInterface {
  title: string;
  aboutMeDescription: () => JSX.Element;
  registerButton: string;
  login: {
    title: string;
    email: string;
    password: string;
    emailError: string;
    emailPlaceholder: string;
    passwordError: string;
    passwordPlaceholder: string;
    button: string;
    bottom: {
      forgot: string;
      signup: string;
      donthave: string;
    };
  };
  confirm: {
    title: string;
    email: string;
    checkEmail: string;
    back: string;
  };
  forgetPassword: ForgetPassword;
  logout: {
    back: string;
    backButton: string;
    title: string;
    description: string;
  };
  errors: ErrorFields;
}
