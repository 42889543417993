export const FooterResourcesBS: IFooterResources = {
  maindropdown: {
    logout: "Odjava",
    myaccount: "Profil",
    notfound: "Odjavljeni ste",
    welcome: "Dobrodošli!",
  },
  notifications: {
    clearall: "Ukloni sve",
    notfound: "Nema novih obavještenja",
    title: "Obavještenja",
    noNotifications: "Nema nepročitanih obavještenja.",
    notificationTypes: {
      unknown:
        "Hm... Some weird notification. Please contact the administrator.",
      recordPointLate: (description?: string) => (
        <>Evidencija {description} nije popunjena na vrijeme!</>
      ),
    },
  },
};

export const FooterResourcesEN: IFooterResources = {
  maindropdown: {
    logout: "Logout",
    myaccount: "My Account",
    notfound: "Not logged in",
    welcome: "Welcome",
  },
  notifications: {
    clearall: "Clear all",
    notfound: "No new notifications",
    title: "Notification",
    noNotifications: "You have no notifications.",
    notificationTypes: {
      unknown:
        "Hm... Some weird notification. Please contact the administrator.",
      recordPointLate: (description?: string) => (
        <>Tacka evidencije {description} kasni</>
      ),
    },
  },
};

export const FooterResourcesDE: IFooterResources = {
  maindropdown: {
    logout: "Abmelden",
    myaccount: "Mein Konto",
    notfound: "Nicht eingeloggt",
    welcome: "Willkommen",
  },
  notifications: {
    clearall: "Alle Löschen",
    notfound: "Keine neuen Benachrichtigungen",
    title: "Benachrichtigungen",
    noNotifications: "Sie haben keine neuen Benachrichtigungen.",
    notificationTypes: {
      recordPointLate: (description?: string) => (
        <>Tacka evidencije {description} kasni</>
      ),
      unknown:
        "Hm... Irgendeine merkwürdige Benachrichtigung. Bitte kontaktieren Sie den Administrator.",
    },
  },
};

interface MainDropdown {
  notfound: string;
  myaccount: string;
  logout: string;
  welcome: string;
}

interface Notifications {
  title: string;
  notfound: string;
  clearall: string;
  notificationTypes: {
    unknown: string;
    recordPointLate: (description?: string) => React.ReactFragment;
  };
  noNotifications: string;
}

export interface IFooterResources {
  maindropdown: MainDropdown;
  notifications: Notifications;
}
