import { AuthActionTypes } from "../auth/constants";
import { CompanyOption } from "../../pages/RegistracijaKompanije/OdabirKompanije";

// ---- Redux Store Interface -----
export interface IReduxStore {
  Auth?: IReduxAuth;
}

export interface IReduxAuth {
  user?: {
    user?: IUserData;
  };
  userLoggedIn?: boolean;
  loading?: boolean;
  passwordReset?: boolean;
  userLogout?: boolean;
  userSignUp?: boolean;
  error?: boolean | string;
  passwordChange?: boolean;
  resetPasswordSuccess?: boolean;
}

export enum EnumStages {
  NEW = "NEW",
  INPROGRESS = "INPROGRESS",
  DONE = "DONE",
}

export interface CreateCIPPEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  recordPointId?: number;
  userId?: number;
}

export interface CreateCIPOEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  type?: string;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  recordPointId?: number;
  userId?: number;
}

export interface CreateDezinfekcijaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsioc?: string | null;
  potpisIzvrsioca?: string | null;
  slikaDeklaracije?: string[] | null;
  sredstvo?: number[];
  recordPointId?: number;
  userId?: number;
}

export interface CreateDezinsekcijaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  slikaDeklaracije?: string[] | null;
  sredstvo?: number[];
  recordPointId?: number;
  userId?: number;
}

export interface CreateDezinsekcijaEvd_KontrolaInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKontrole?: Date;
  vrijemeKontrole?: Date;
  izvrsilacKontrole?: string;
  potpisanKontrolora?: boolean;
  opisKontrole?: string;
  stanjeKontrole?: boolean;
  recordPointId?: number;
  userId?: number;
}

export interface CreateDezinsekcijaEvd_KorekcijaInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKorekcije?: Date;
  vrijemeKorekcije?: Date;
  izvrsilacKorekcije?: string;
  potpisKorekcije?: string;
  opisKorekcije?: string;
  stanjeKorekcije?: string;
  recordPointId?: number;
  userId?: number;
}

export interface CreateDeratizacijaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  slikaDeklaracije?: string[] | null;
  sredstvo?: number[];
  recordPointId?: number;
  userId?: number;
}

export interface CreateDeratizacijaEvd_KontrolaInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKontrole?: Date;
  vrijemeKontrole?: Date;
  izvrsilacKontrole?: string;
  potpisanKontrolora?: boolean;
  opisKontrole?: string;
  stanjeKontrole?: boolean;
  recordPointId?: number;
  userId?: number;
}

export interface CreateDeratizacijaEvd_KorekcijaInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date;
  vrijeme?: Date;
  imePrezime?: string;
  potpisano?: string;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKorekcije?: Date;
  vrijemeKorekcije?: Date;
  izvrsilacKorekcije?: string;
  potpisKorekcije?: string;
  opisKorekcije?: string;
  stanjeKorekcije?: string;
  recordPointId?: number;
  userId?: number;
}

// ---------------------------------
export interface IAuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;
  payload: {} | string;
}

// --- For User ---
export enum EnumUserType {
  ADMIN = "ADMIN",
  USER = "USER",
  MANAGER = "MANAGER",
  TRDPARTY = "TRDPARTY",
}

export type IRecordPointCard = {
  recordPointId: number;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  recordFields: EnumPoljaEvidencije;
  users?: IUserData[];
  userId?: number;
  updatedAt: Date;
  name: string;
};

export interface IMenuAccessRightsUser {
  links: ILinkMenu[];
}

export interface ILinkMenu {
  pageName: EnumPageName;
  subPages: EnumSubPageName[];
}

export enum EnumPageName {
  HOME = "home",
  KOMPANIJA = "kompanija",
  EVIDENCIJA = "evidencija",
  STATISTIKA = "statistika",
  RADNOVRIJEME = "radnovrijeme",
  POHVALEIKRITIKE = "pohvaleikritike",
  ADMINISTRACIJA = "administration",
  LISTE = "liste",
  FOOTER = "footer",
}

export enum EnumSubPageName {
  PLANIRANIZADACI = "planiranizadaci",
  EVIDENCIJAPOPOTREBI = "evidencijapopotrebi",
  PRIJAVAODJAVA = "prijavaodjava",
  REGISTRACIJA = "company-settings",
  RECORDPOINT = "points-settings",
  USERS = "user-settings",
  ADDUSERS = "add-users",
  EDITUSERS = "edit-users",
  RECORDSSETINGS = "records-settings",
  QRCODES = "qr-settings",
  LISTAKOMPANIJA = "listakompanija",
  LISTAEVIDENCIJA = "listaevidencija",
  LISTARADNOGVREMENA = "listaradnogvremena",
  LISTARADNOGVREMENASUM = "listaradnogvremenasum",
  IMAGEPAGE = "image-page",
  ABOUTUS = "about-us",
}

export interface ILocalStorage {
  user: IUserData;
  company?: CompanyOption;
}

export interface IUserData {
  userId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  lastLogin?: Date;
  type: EnumUserType | null;
  position?: string | null;
  menuAccessRights?: IMenuAccessRightsUser | null;
  email: string;
  password: string;
  tempPassword?: string;
  phoneNumber?: string | null;
  active?: boolean | null;
  fullName?: string | null;
  potpis?: string | null;
  branches?: IBranch[] | null;
  sessionId?: string;
  profilePicture?: string | null;
}

// ---- Record Point ----
export interface IRecordPointClass {
  recordPointId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  startDate: Date;
  endDate: Date;
  name: string;
  url: string | null;
  qrCode: string | null;
  accessRights: number[] | null;
  publicAccess: boolean;
  active: boolean;
  bellNotification?: boolean;
  stages: EnumStages | null;
  Branch?: IBranch | null;
  RecordTraceConfig?: IRecordTraceConfigClass | null;
  CIPOEvd?: CIPOEvd[] | null;
  CIPPEvd?: CIPPEvd[] | null;
  DeratizacijaEvd?: DeratizacijaEvd[] | null;
  DezinfekcijaEvd?: DezinfekcijaEvd[] | null;
  DezinsekcijaEvd?: DezinsekcijaEvd[] | null;
  DeratizacijaEvd_Kontrola?: DeratizacijaEvd_Kontrola[] | null;
  DeratizacijaEvd_Korekcija?: DeratizacijaEvd_Korekcija[] | null;
  DezinsekcijaEvd_Kontrola?: DezinsekcijaEvd_Kontrola[] | null;
  DezinsekcijaEvd_Korekcija?: DezinsekcijaEvd_Korekcija[] | null;
  DezSrEvd?: DezSrEvd[] | null;
  DobavljacEvd?: DobavljacEvd[] | null;
  DostavaEvd?: DostavaEvd[] | null;
  EduZapEvd?: EduZapEvd[] | null;
  MolEvd?: MolEvd[] | null;
  OpremaEvd?: OpremaEvd[] | null;
  OtpadEvd?: OtpadEvd[] | null;
  PrijemRobeEvd?: PrijemRobeEvd[] | null;
  SanEvd?: SanEvd[] | null;
  TemperaturaEvd?: TemperaturaEvd[] | null;
  TemperaturaOgranicenaEvd?: TemperaturaOgranicenaEvd[] | null;
  TemperaturaOgranicenaKKTEvd?: TemperaturaOgranicenaKKTEvd[] | null;
  TemperaturaKKTProizvodiEvd?: TemperaturaKKTPROIZVODI[] | null;
}

export enum EnumBellNotificationType {
  recordPointLate = "recordPointLate",
}

export interface INotificationClass {
  notificationId: number;
  createdAt: Date;
  updatedAt: Date;
  bellType?: EnumBellNotificationType;
  seen?: boolean;
  User?: IUser;
}

export interface IUser {
  userId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  lastLogin?: Date;
  type?: EnumUserType;
  position?: string;
  menuAccessRights?: IMenuAccessRightsUser;
  email: string;
  password: string;
  tempPassword?: string;
  phoneNumber?: string;
  active?: boolean;
  fullName?: string;
  sessionId?: string;
  potpis?: string;
  profilePicture?: string;
  branches?: IBranch[];
  notifications?: INotificationClass[];
}

export interface IRecordNotifications {
  recordNotificationsId: number;
  createdAt: Date;
  updatedAt: Date;
  sent?: boolean;
  userId: number;
  RecordTraceConfig?: IRecordTraceConfigClass;
}

export interface IRecordTraceConfigClass {
  recordTraceConfigId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  status: boolean;
  branches: IBranch[] | null;
  fkUserId: number | null;
  fkBranchId: number | null;
  typeList: EnumTypeList | null;
  label: string | null;
  category: EnumRTCCategory | null;
  title: string;
  description: string | null;
  config: IRTCConfig | null;
  tempConfig: ITempConfig | null;
  notification: INotification | null;
  recordFields: EnumPoljaEvidencije | null;
  recordPoint: IRecordPointClass[] | null;
  revizija?: string;
  descriptionMarkdown?: string;
  recordNotifications?: IRecordNotifications[];
}

export interface CreateDostavaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  posiljalac?: string | null;
  brojRacuna?: string | null;
  potpisPosiljalac: string;
  primalac?: string | null;
  potpisPrimalac?: string | null;
  ispravnaPosiljka: boolean;
  dokument?: string[] | null;
  primjedba?: string | null;
  korektivnaMjera?: string | null;
  recordPointId?: number;
  userId?: number;
}

export interface DostavaEvd {
  dostavaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: string | null;
  posiljalac?: string | null;
  brojRacuna?: string | null;
  potpisPosiljalac: string;
  primalac?: string | null;
  potpisPrimalac?: string | null;
  ispravnaPosiljka: boolean;
  dokument?: string[] | null;
  primjedba?: string | null;
  korektivnaMjera?: string | null;
}

export interface CreateEduZapEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  datumObuke?: Date | null;
  mjestoObuke?: string | null;
  tip: EnumEduZapTip;
  izvrsilacObuke?: string | null;
  tema?: string | null;
  cilj?: string | null;
  verifikacijaEfikasnosti?: string | null;
  imePrezime?: string | null;
  radnoMjesto?: string | null;
  verifikacijaPrisustvaPotpis?: string | null;
  verifikacijaEdukatoraPotpis?: string | null;
  dokument?: string[] | null;
  recordPointId?: number;
  userId?: number;
}

export interface EduZapEvd {
  eduZapEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  datumObuke?: Date | null;
  mjestoObuke?: string | null;
  tip: EnumEduZapTip;
  izvrsilacObuke?: string | null;
  tema?: string | null;
  cilj?: string | null;
  verifikacijaEfikasnosti?: string | null;
  imePrezime?: string | null;
  radnoMjesto?: string | null;
  verifikacijaPrisustvaPotpis?: string | null;
  verifikacijaEdukatoraPotpis?: string | null;
  dokument?: string[] | null;
}

export enum EnumEduZapTip {
  INTERNA = "INTERNA",
  EXTERNA = "EXTERNA",
  UNDEFINED = "UNDEFINED",
}

export interface CreateMolEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  datum?: Date | null;
  opis?: string | null;
  izvrsioc?: string | null;
  potpisIzvrsioca?: string | null;
  recordPointId?: number;
  userId?: number;
}

export interface MolEvd {
  molEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  datum?: Date | null;
  opis?: string | null;
  izvrsioc?: string | null;
  potpisIzvrsioca?: string | null;
}

export interface CreateOpremaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  tip?: string | null;
  datum?: Date | null;
  vrijeme?: Date | null;
  izvrsioc?: string | null;
  potpisIzvrsioca?: string | null;
  opis?: string | null;
  recordPointId?: number;
  userId?: number;
}

export interface OpremaEvd {
  opremaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  tip?: string | null;
  datum?: Date | null;
  vrijeme?: Date | null;
  izvrsioc?: string | null;
  potpisIzvrsioca?: string | null;
  opis?: string | null;
}

export interface CreateOtpadEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  datum?: Date | null;
  vrijeme?: Date | null;
  vrstaOtpada?: string | null;
  kolicina?: number | null;
  jedinicaMjere?: string | null;
  primaoc?: string | null;
  potpisPrimaoc?: string | null;
  adekvatnoPrikupljen: boolean;
  korektivnaMjera?: string | null;
  recordPointId?: number;
  userId?: number;
}

export interface OtpadEvd {
  otpadEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  datum?: Date | null;
  vrijeme?: Date | null;
  vrstaOtpada?: string | null;
  kolicina?: number | null;
  jedinicaMjere?: string | null;
  primaoc?: string | null;
  potpisPrimaoc?: string | null;
  adekvatnoPrikupljen: boolean;
  korektivnaMjera?: string | null;
}

export interface CreatePrijemRobeEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  primalac?: string | null;
  potpisano?: string | null;
  datum?: Date | null;
  vrijeme?: Date | null;
  posiljalac?: string | null;
  potpisPosiljalac?: string | null;
  ispravnaPosiljka?: boolean | null;
  dokument?: string[] | null;
  primjedba?: string | null;
  korektivnaMjera?: string | null;
  recordPointId?: number;
  userId?: number;
}

export interface PrijemRobeEvd {
  prijemRobeEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  primalac?: string | null;
  potpisano?: string | null;
  datum?: Date | null;
  vrijeme?: Date | null;
  posiljalac?: string | null;
  potpisPosiljalac?: string | null;
  ispravnaPosiljka?: boolean | null;
  dokument?: string[] | null;
  primjedba?: string | null;
  korektivnaMjera?: string | null;
}

export interface RzvEvd {
  rzvEvdId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateSanEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime: string | null;
  potpisano: string | null;
  vaziOd: Date | null;
  vaziDo: Date | null;
  uposlenik: string | null;
  dokument?: string[] | null;
  recordPointId?: number;
  userId?: number;
}

export interface SanEvd {
  sanEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime: string | null;
  potpisano: string | null;
  vaziOd: Date | null;
  vaziDo: Date | null;
  uposlenik: string | null;
  dokument?: string[] | null;
}

export interface CIPPEvd {
  cippEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface CIPOEvd {
  cipoEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  alarm?: IAlarmType[] | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface IAlarmType {
  type: EnumNotificationType;
  dateTime: Date;
  executed: Date;
}

export interface CreateTemperaturaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  vrijeme: Date | null;
  ocitanaTemperatura: number | null;
  dozvoljenaTempMin: number | null;
  dozvoljenaTempMax: number | null;
  dozvoljenoOdstupanje: number | null;
  recordPointId?: number;
  userId?: number;
}

export interface ITempConfig {
  dozvoljenoOdstupanje?: number;
  dozvoljenaTempMax?: number;
  dozvoljenaTempMin?: number;
}

export interface CreateTemperaturaOgranicenaEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  ocitanaTemperatura: number | null;
  recordPointId?: number;
  userId?: number;
  napomena?: string;
}

export interface CreateTemperaturaOgranicenaKKTEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  ocitanaTemperatura: number | null;
  recordPointId?: number;
  userId?: number;
  nakonKorekcijeTemperatura?: string;
  korektivnaMjera: boolean;
}

export interface CreateTemperaturaKKTProizvodEvdInput {
  createdAt?: Date;
  updatedAt?: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  ocitanaTemperatura: number | null;
  recordPointId?: number;
  userId?: number;
  nakonKorekcijeTemperatura?: string;
  korektivnaMjera: boolean;
  proizvod?: string;
}

export interface TemperaturaEvd {
  temperaturaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  vrijeme: Date | null;
  ocitanaTemperatura: number | null;
  dozvoljenaTempMin: number | null;
  dozvoljenaTempMax: number | null;
  dozvoljenoOdstupanje: number | null;
  stanje: boolean;
  datumKorekcije: Date | null;
  vrijemeKorekcije: Date | null;
  opisMjeereKorekcije: string | null;
  ocitanaTemperaturaKorekcije: number | null;
  dozvoljenaTempMinKorekcije: number | null;
  dozvoljenaTempMaxKorekcije: number | null;
  dozvoljenoOdstupanjeKorekcije: number | null;
  imePrezimeKorekcije: string | null;
  potpisIzvrsiocaKorekcije: string | null;
  userId?: number | null;
}

export interface TemperaturaOgranicenaEvd {
  temperaturaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  ocitanaTemperatura: number | null;
  userId?: number | null;
  napomena?: string;
}

export interface TemperaturaOgranicenaKKTEvd {
  temperaturaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  ocitanaTemperatura: number | null;
  userId?: number | null;
  korektivnaMjera?: Boolean;
  nakonKorekcijeTemperatura?: string;
}

export interface TemperaturaKKTPROIZVODI {
  temperaturaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  proizvod?: string;
  imePrezime: string | null;
  potpisano: string | null;
  datum: Date | null;
  ocitanaTemperatura: number | null;
  userId?: number | null;
  korektivnaMjera?: Boolean;
  nakonKorekcijeTemperatura?: string;
}

export interface DeratizacijaEvd {
  deratizacijaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  slikaDeklaracije?: string[] | null;
  datumKontrole?: Date | null;
  vrijemeKontrole?: Date | null;
  izvrsilacKontrole?: string | null;
  potpisanKontrolora?: boolean | null;
  opisKontrole?: string | null;
  stanjeKontrole?: boolean | null;
  datumKorekcije?: Date | null;
  vrijemeKorekcije?: Date | null;
  izvrsilacKorekcije?: string | null;
  potpisKorekcije?: string | null;
  opisKorekcije?: string | null;
  stanjeKorekcije?: string | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface DeratizacijaEvd_Kontrola {
  deratizacijaEvd_KontrolaId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKontrole?: Date | null;
  vrijemeKontrole?: Date | null;
  izvrsilacKontrole?: string | null;
  potpisanKontrolora?: boolean | null;
  opisKontrole?: string | null;
  stanjeKontrole?: boolean | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface DeratizacijaEvd_Korekcija {
  deratizacijaEvd_KorekcijaId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKorekcije?: Date | null;
  vrijemeKorekcije?: Date | null;
  izvrsilacKorekcije?: string | null;
  potpisKorekcije?: string | null;
  opisKorekcije?: string | null;
  stanjeKorekcije?: string | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface DezinfekcijaEvd {
  dezinfekcijaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano: string | null;
  izvrsioc?: string | null;
  potpisIzvrsioca?: string | null;
  slikaDeklaracije?: string[] | null;
  sredstvo?: number[] | null;
  userId?: number | null;
}

export interface DezinsekcijaEvd {
  dezinsekcijaEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  slikaDeklaracije?: string[] | null;
  datumKontrole?: Date | null;
  vrijemeKontrole?: Date | null;
  izvrsilacKontrole?: string | null;
  potpisanKontrolora?: boolean | null;
  opisKontrole?: string | null;
  stanjeKontrole?: boolean | null;
  datumKorekcije?: Date | null;
  vrijemeKorekcije?: Date | null;
  izvrsilacKorekcije?: string | null;
  potpisKorekcije?: string | null;
  opisKorekcije?: string | null;
  stanjeKorekcije?: string | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface DezinsekcijaEvd_Kontrola {
  dezinsekcijaEvd_KontrolaId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKontrole?: Date | null;
  vrijemeKontrole?: Date | null;
  izvrsilacKontrole?: string | null;
  potpisanKontrolora?: boolean | null;
  opisKontrole?: string | null;
  stanjeKontrole?: boolean | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface DezinsekcijaEvd_Korekcija {
  dezinsekcijaEvd_KorekcijaId: number;
  createdAt: Date;
  updatedAt: Date;
  datum?: Date | null;
  vrijeme?: Date | null;
  imePrezime?: string | null;
  potpisano?: string | null;
  izvrsilac?: string | null;
  potpisIzvrsioca?: string | null;
  datumKorekcije?: Date | null;
  vrijemeKorekcije?: Date | null;
  izvrsilacKorekcije?: string | null;
  potpisKorekcije?: string | null;
  opisKorekcije?: string | null;
  stanjeKorekcije?: string | null;
  RecordPoint?: IRecordPointClass | null;
  userId?: number | null;
}

export interface CreateDezSrEvdInput {
  createdAt: Date;
  updatedAt: Date;
  naziv?: string | null;
  vrsta?: string | null;
  primjena?: string | null;
  upustvoZaUpotrebu?: string | null;
  slika?: string[] | null;
  recordPointId?: number;
  userId?: number;
  imePrezime?: string | null;
  potpisano?: string | null;
}

export interface DezSrEvd {
  dezSrEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  naziv?: string | null;
  vrsta?: string | null;
  primjena?: string | null;
  upustvoZaUpotrebu?: string | null;
  slika?: string[] | null;
  userId?: number | null;
  imePrezime?: string | null;
  potpisano?: string | null;
}

export interface CreateDobavljacEvdInput {
  createdAt: Date;
  updatedAt: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  name: string;
  address?: IAddress | null;
  phoneNumber?: string | null;
  idTax?: string | null;
  pdvTax?: string | null;
  productType?: string | null;
  recordPointId?: number;
  userId?: number;
}

export interface DobavljacEvd {
  dobavljacEvdId: number;
  createdAt: Date;
  updatedAt: Date;
  imePrezime?: string | null;
  potpisano?: string | null;
  name: string;
  address?: IAddress | null;
  phoneNumber?: string | null;
  idTax?: string | null;
  pdvTax?: string | null;
  productType?: string | null;
  userId?: number | null;
}

// ---- For Branch ----
export interface IBranch {
  branchId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  status: boolean;
  name: string;
  address?: IAddress | null;
  email: string;
  phoneNumber: string;
  salesPerson?: string;
  // TODO: Add Object Type Interface
  objectType?: string;
  idTax?: string | null;
  pdvTax?: string | null;
  contactPerson?: string | null;
  type?: string | null;
  recordPoint?: IRecordPoint[] | null;
  users?: IUserData[] | null;
  Company?: ICompany | null;
}

export interface IAddress {
  street: string;
  zipCode: string;
  city: string;
  country: string;
}

export enum EnumRTCCategory {
  TEMPLATE = "template",
  EVIDENCIJA = "evidencija",
}

export enum EnumTypeList {
  UNDEFINED = "",
  REGULAR = "redovna",
  OCCASIONALLY = "popotrebi",
}

export enum EnumTypeListUppercase {
  UNDEFINED = "",
  REGULAR = "REGULAR",
  OCCASIONALLY = "OCCASIONALLY",
}

// --- For Record Point ---
// Lista usera koji mogu obaviti zadatak
export interface IDeligatedUsers {
  users: IDeligatedUsersDefinition[];
}

export interface IDeligatedUsersDefinition {
  userId: number;
  read: boolean;
  write: boolean;
}

interface IRecordPoint {
  recordPointId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  status: boolean;
  name: string;
  url?: string;
  qrCode?: string;
  deligatedUsers?: IDeligatedUsers[];
  publicAccess: boolean;
}

interface ICompany {
  companyId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  status: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  address?: IAddress;
  idTax?: string;
  pdvTax?: string;
  contactPerson?: string;
  salesPerson?: string;
  payment?: IPayment[];
  invoice?: Invoice[];
  branch?: IBranch[];
}

interface IPayment {
  paymentId: number;
  createdAt: Date;
  updatedAt: Date;
  paymentDate: Date;
  summ?: number;
  invoiceNumber: string[];
  Company?: ICompany;
}

export interface Invoice {
  invoiceId: number;
  createdAt: Date;
  updatedAt: Date;
  summ?: number;
  Company?: ICompany;
}

export interface UserBySessionIDResponse {
  UserbySessionID: {
    userId?: number;
    sessionId?: string;
  };
}

// ---- Record Trace Config ----
// TODO: Dodavati postepeno polja evidencija
export enum EnumPoljaEvidencije {
  CIPP_V1 = "CIPP_V1",
  CIPO_V1 = "CIPO_V1",
  Dezinfekcija_V1 = "Dezinfekcija_V1",
  Dezinsekcija_V1 = "Dezinsekcija_V1",
  Deratizacija_V1 = "Deratizacija_V1",
  Dezinsekcija_Kontrola_V1 = "Dezinsekcija_Kontrola_V1",
  Dezinsekcija_Korekcija_V1 = "Dezinsekcija_Korekcija_V1",
  Deratizacija_Kontrole_V1 = "Deratizacija_Kontrole_V1",
  Deratizacija_Korekcije_V1 = "Deratizacija_Korekcije_V1",
  DezSrEvd_V1 = "DezSrEvd_V1",
  DobavljacEvd_V1 = "DobavljacEvd_V1",
  DostavaEvd_V1 = "DostavaEvd_V1",
  EduZapEvd_V1 = "EduZapEvd_V1",
  MolEvd_V1 = "MolEvd_V1",
  OpremaEvd_V1 = "OpremaEvd_V1",
  OtpadEvd_V1 = "OtpadEvd_V1",
  PrijemRobeEvd_V1 = "PrijemRobeEvd_V1",
  RzvEvd_V1 = "RzvEvd_V1",
  SanEvd_V1 = "SanEvd_V1",
  TemperaturaEvd_V1 = "TemperaturaEvd_V1",
  TemperaturaOgranicenaEvd_V1 = "TemperaturaOgranicenaEvd_V1",
  TemperaturaOgranicenaKKTEvd_V1 = "TemperaturaOgranicenaKKTEvd_V1",
  TemperaturaKKTProizvodiEvd_V1 = "TemperaturaKKTProizvodiEvd_V1",
}

export interface IRTCConfig {
  recordPoints?: IRecordPointsRTC[];
  frequency?: IFrequencies[];
  users?: IUserCategories[];
}

export interface IFrequencies {
  type: EnumFrequency;
  startDate: string;
  endDate?: string;
  occurrance?: number;
  beginDate?: string;
}

export enum EnumFrequency {
  DNEVNI = "DNEVNI",
  SEDMICNI = "SEDMICNI",
  MJESECNI = "MJESECNI",
  GODISNJI = "GODISNJI",
  VISEGODISNJI = "VISEGODISNJI",
  POPOTREBI = "POPOTREBI",
}

export interface IUserCategories {
  recordPoint: string | IRecordPointsRTC[];
  category: boolean;
  selected: boolean;
}

export interface IRecordPointsRTC {
  recordPoint: string | IRecordPointsRTC[];
  selected: boolean;
}

export enum EnumNotificationType {
  email1 = "email1",
  email2 = "email2",
  email3 = "email3",
  sms1 = "sms1",
  sms2 = "sms2",
  sms3 = "sms3",
  call1 = "call1",
  call2 = "call2",
  call3 = "call3",
}

export enum EnumTempDeterminer {
  DANA = "DANA",
  MINUTA = "MINUTA",
  SATI = "SATI",
}

export enum EnumAlarmType {
  beforeStart = "beforeStart",
  afterStart = "afterStart",
  beforeEnd = "beforeEnd",
  afterEnd = "afterEnd",
}

export interface INotificationType {
  type: EnumNotificationType;
  alarmType: EnumAlarmType;
  tempDeterminer: EnumTempDeterminer;
  countdown: number;
}

export interface INotification {
  notification: INotificationType[] | undefined;
  defaultNotification: boolean;
}

export enum LoadFileType {
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
}

export interface FileReturn {
  file: string;
  __typename: string;
}

export interface GetFileReturnResponse {
  getFile: FileReturn;
}

export enum EnumRadnoVrijemeType {
  RADNOVRIJEME = "RADNOVRIJEME",
  RADUSMJENAMA = "RADUSMJENAMA",
  TERENSKIRAD = "TERENSKIRAD",
  DEZURA = "DEZURA",
  PRIPRAVNOST = "PRIPRAVNOST",
  ODSUSTVO = "ODSUSTVO",
}

export interface IRadnoVrijemeEvd {
  radnoVrijemeId: number;
  createdAt: Date;
  updatedAt: Date;
  entryType?: EnumRadnoVrijemeType;
  pocetak?: Date;
  zavrsetak?: Date;
  pocetakPauze?: Date;
  zavrsetakPauze?: Date;
  imePrezime?: string;
  potpisano?: string;
  userId?: number;
  napomena?: string;
}

export interface IRadnoVrijemeEvdInput {
  entryType?: EnumRadnoVrijemeType | "";
  pocetak?: string;
  zavrsetak?: string;
  pocetakPauze?: string;
  zavrsetakPauze?: string;
  napomena?: string;
  radnoVrijemeId?: number;
  potpisano?: string;
  userId?: number;
  imePrezime?: string;
  __typename?: string;
}
