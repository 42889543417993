import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  styledPublicRoutes,
} from "./index";

import DefaultLayout from "../layouts/Default";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import { ILocalStorage } from "../redux/interfaces";
import { LayoutTypes } from "../constants/layout";
import { RootState } from "../redux/store";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import TwoColumnLayout from "../layouts/TwoColumn/";
import VerticalLayout from "../layouts/Vertical";
import { paths } from "./paths";
import { useSelector } from "react-redux";

interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const storedUser = localStorage.getItem("user");

  // Check if the session Id is valid
  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();
  const navigate = useNavigate();

  let person: ILocalStorage | null = null;
  if (storedUser) {
    person = JSON.parse(storedUser);
  }

  useEffect(() => {
    if (person && person?.user.lastLogin === null) {
      navigate(paths.auth.firstLogin);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <React.Fragment>
      <ScrollToTop>
        <Routes>
          <Route>
            {publicProtectedFlattenRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <DefaultLayout {...props} layout={layout}>
                    {route.element}
                  </DefaultLayout>
                }
                key={idx}
              />
            ))}
          </Route>

          <Route>
            {styledPublicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<Layout {...props}>{route.element}</Layout>}
                key={idx}
              />
            ))}
          </Route>

          <Route>
            {authProtectedFlattenRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  !person?.user.sessionId ? (
                    <Navigate
                      to={{
                        pathname: "/auth/login",
                        search: "next=" + route.path,
                      }}
                      replace={true}
                    />
                  ) : (
                    <Layout {...props}>{route.element}</Layout>
                  )
                }
                key={idx}
              />
            ))}
          </Route>

          <Route path="*" element={<Navigate to={paths.errors.error404} />} />
        </Routes>
      </ScrollToTop>
    </React.Fragment>
  );
};

export default AllRoutes;
