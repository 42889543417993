export const ListaKompanijaResourcesBS: ListaKompanijaResourcesBSInterface = {
  title: "Kompanije",
  listaTacaka: "Tačke primjene",
  listaRadnihTacaka: "Radno mjesto i korisnici",
  branchButton: "Odaberi filijalu",
  next: "Naprijed",
  back: "Nazad",
  branch: {
    placeholder: "Odabir poslovnice",
    notFound: "Nije pronađena poslovnica",
  },
  errorBranches:
    "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
};

export interface ListaKompanijaResourcesBSInterface {
  title: string;
  listaTacaka: string;
  listaRadnihTacaka: string;
  branchButton: string;
  next: string;
  back: string;
  branch: {
    placeholder: string;
    notFound: string;
  };
  errorBranches: string;
}
