export const RegistracijaKompanijeResourcesBS: RegistracijaKompanijeResourcesBSInterface =
  {
    dupplicateError: "Dodavanje nije uspjelo, uneseni podatci su duplicirani",
    izmjenaPoslovnice: {
      title: "Izmjena podataka poslovnice",
      next: "Spasi",
      back: "Nazad",
      dialogSuccess: {
        title: "Uspješno!",
        body: "Promijenili ste podatke poslovnice!",
        buttonOk: "OK",
      },
      errorLoading:
        "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
      errorUpdate:
        "Izmjena poslovnice nije uspjela. Molimo kontaktirajte administratora.",
    },
    odabirKompanije: {
      title: "Kompanije",
      dropdownLabel: "Odabir kompanije:",
      dropdownPlaceholder: "Odabir kompanije",
      dropdownNoOptionsMessage: "Nema unijetih kompanija",
      addCompanyButton: "Dodaj novu kompaniju",
      next: "Odaberi",
      back: "Nazad",
      errorLoading:
        "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
    },
    odabirPoslovnice: {
      title: "Poslovnice",
      dropdownLabel: "Odabir poslovnice:",
      dropdownPlaceholder: "Odabir poslovnice",
      dropdownNoOptionsMessage: "Nema unijetih poslovnica",
      addCompanyButton: "Dodaj novu poslovnicu",
      next: "Izmjeni",
      back: "Nazad",
      errorLoading:
        "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
    },
    dodavanjeKompanije: {
      title: "Dodavanje kompanije",
      formButton: "Spasi",
      next: "Naprijed",
      back: "Nazad",
      companyName: {
        label: "Naziv Kompanije",
        name: "companyName",
        error: "Niste unijeli naziv kompanije",
      },
      street: {
        label: "Ulica i broj",
        name: "street",
        error: "Niste unijeli street",
      },
      city: {
        label: "Grad",
        name: "city",
        error: "Niste unijeli grad",
      },
      zipCode: {
        label: "Poštanski broj",
        name: "zipCode",
        error: "Niste unijeli poštanski broj",
        validation: "Neispravan broj",
      },
      country: {
        label: "Država",
        name: "country",
        error: "Niste unijeli državu",
      },
      email: {
        label: "E-mail",
        name: "email",
        error: "Niste unijeli e-mail",
        validation: "Niste unijeli ispravan e-mail",
      },
      phone: {
        label: "Telefon",
        name: "phone",
        error: "Niste unijeli telefon",
        validation: "Neispravan broj",
      },
      idCompanyNumber: {
        label: "ID broj",
        name: "idCompanyNumber",
        error: "Niste unijeli ID broj",
        validation: "Neispravan broj",
      },
      vatCompanyNumber: {
        label: "PDV broj",
        name: "vatCompanyNumber",
        error: "Niste unijeli PDV broj",
        validation: "Neispravan broj",
      },
      contactPerson: {
        label: "Kontakt osoba",
        name: "contactPerson",
        error: "Niste unijeli kontakt osobu",
      },
      saleRepresentative: {
        label: "Prodajni agent",
        name: "saleRepresentative",
        error: "Niste unijeli prodajnog agenta",
      },
      branchName: {
        label: "Naziv poslovnice",
        name: "branchName",
        error: "Niste unijeli naziv poslovnice",
      },
      dialogSuccess: {
        title: "Uspješno!",
        body: "Dodali ste kompaniju u bazu podataka!",
        buttonOk: "OK",
      },
    },
    dodavanjePoslovnice: {
      title: "Dodavanje poslovnice",
      type: "Tip objekta",
      name: "objectType",
      error: "Niste odabrali poslovnicu",
      options: [
        {
          value: "",
          label: "Molimo odaberite tip objekta",
        },
        {
          value: "Punionica meda",
          label: "Punionica meda",
        },
        {
          value: "Ugostiteljski objekat",
          label: "Ugostiteljski objekat",
        },
        {
          value: "Pekara",
          label: "Pekara",
        },
      ],
      dialogSuccess: {
        title: "Uspješno!",
        body: "Dodali ste poslovnicu u bazu podataka!",
        buttonOk: "OK",
      },
    },
  };

export interface RegistracijaKompanijeResourcesBSInterface {
  dupplicateError: string;
  izmjenaPoslovnice: {
    title: string;
    next: string;
    back: string;
    dialogSuccess: {
      title: string;
      body: string;
      buttonOk: string;
    };
    errorLoading: string;
    errorUpdate: string;
  };
  odabirKompanije: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    errorLoading: string;
    next: string;
    back: string;
  };
  odabirPoslovnice: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    next: string;
    back: string;
    errorLoading: string;
  };
  dodavanjeKompanije: {
    title: string;
    formButton: string;
    next: string;
    back: string;
    companyName: {
      label: string;
      name: string;
      error: string;
    };
    street: {
      label: string;
      name: string;
      error: string;
    };
    city: {
      label: string;
      name: string;
      error: string;
    };
    zipCode: {
      label: string;
      name: string;
      error: string;
      validation: string;
    };
    country: {
      label: string;
      name: string;
      error: string;
    };
    email: {
      label: string;
      name: string;
      error: string;
      validation: string;
    };
    phone: {
      label: string;
      name: string;
      error: string;
      validation: string;
    };
    idCompanyNumber: {
      label: string;
      name: string;
      error: string;
      validation: string;
    };
    vatCompanyNumber: {
      label: string;
      name: string;
      error: string;
      validation: string;
    };
    contactPerson: {
      label: string;
      name: string;
      error: string;
    };
    saleRepresentative: {
      label: string;
      name: string;
      error: string;
    };
    branchName: {
      label: string;
      name: string;
      error: string;
    };
    dialogSuccess: {
      title: string;
      body: string;
      buttonOk: string;
    };
  };
  dodavanjePoslovnice: {
    title: string;
    type: string;
    name: string;
    error: string;
    options: {
      value: string;
      label: string;
    }[];
    dialogSuccess: {
      title: string;
      body: string;
      buttonOk: string;
    };
  };
}
