export const TEMPERATURA_V1ResourcesBS: TEMPERATURA_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errorMissingInput: "Molimo popunite sva polja",
  input: {
    signatureTitle: "Potpis",
    datum: {
      label: "Molimo unesite datum",
      placeholder: "Molimo unesite datum",
      name: "datum",
    },
    ocitanaTemperatura: {
      label: "Očitana temperatura",
      name: "ocitanaTemperatura",
      placeholder: "Molimo unesite ocitanu temperaturu",
    },
    dozvoljenaTempMin: {
      label: "Minimalna dozvoljena temperatura",
      name: "dozvoljenaTempMin",
      placeholder: "Molimo unesite minimalnu temperaturu",
    },
    dozvoljenaTempMax: {
      label: "Maksimalna dozvoljena temperatura",
      name: "dozvoljenaTempMax",
      placeholder: "Molimo unesite ocitanu temperaturu",
    },
    dozvoljenoOdstupanje: {
      label: "Dozvoljeno odstupanje",
      name: "dozvoljenoOdstupanje",
      placeholder: "Molimo unesite dozvoljeno odstupanje",
    },
    stanje: {
      label: "Da li je stanje van dozvoljenih vrijednosti?",
    },
    vrijemeKorekcije: {
      label: "Odaberite vrijeme korekcije",
      name: "vrijemeKorekcije",
      placeholder: "Odaberite vrijeme korekcije",
    },
    opisMjeereKorekcije: {
      label: "Opis mjere korekcije",
      name: "opisMjeereKorekcije",
      placeholder: "Odaberite opis mjere korekcije",
    },
    ocitanaTemperaturaKorekcije: {
      label: "Očitana temperatura korekcije",
      name: "ocitanaTemperaturaKorekcije",
      placeholder: "Molimo unesite očitanu temperaturu korekcije",
    },
    dozvoljenaTempMinKorekcije: {
      label: "Minimalna dozvoljena temperatura korekcije",
      name: "dozvoljenaTempMinKorekcije",
      placeholder: "Molimo unesite minimalnu temperaturu korekcije",
    },
    dozvoljenaTempMaxKorekcije: {
      label: "Maksimalna dozvoljena temperatura korekcije",
      name: "dozvoljenaTempMaxKorekcije",
      placeholder: "Molimo unesite ocitanu temperaturu korekcije",
    },
    dozvoljenoOdstupanjeKorekcije: {
      label: "Dozvoljeno odstupanje korekcije",
      name: "dozvoljenoOdstupanjeKorekcije",
      placeholder: "Molimo unesite dozvoljeno odstupanje korekcije",
    },
    imePrezimeKorekcije: {
      name: "imePrezimeKorekcije",
      placeholder: "Molimo unesite ime i prezime korekcije",
      label: "Molimo unesite ime i prezime ",
    },
    potpisIzvrsiocaKorekcije: { label: "Potpis izvrsioca korekcije" },
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface InputField {
  label: string;
  name?: string; // Optional since some fields like 'stanje' and 'potpisIzvrsiocaKorekcije' don't have a 'name'
  placeholder?: string; // Optional since not all fields have it
}

interface Input {
  signatureTitle: string;
  datum: InputField;
  ocitanaTemperatura: InputField;
  dozvoljenaTempMin: InputField;
  dozvoljenaTempMax: InputField;
  dozvoljenoOdstupanje: InputField;
  stanje: InputField;
  vrijemeKorekcije: InputField;
  opisMjeereKorekcije: InputField;
  ocitanaTemperaturaKorekcije: InputField;
  dozvoljenaTempMinKorekcije: InputField;
  dozvoljenaTempMaxKorekcije: InputField;
  dozvoljenoOdstupanjeKorekcije: InputField;
  imePrezimeKorekcije: InputField;
  potpisIzvrsiocaKorekcije: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

export interface TEMPERATURA_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
}
