export const GetQRResourcesBS: GetQRResourcesInterface = {
  title: "Naziv tacke primjene",
  buttons: {
    popuni: "Popuni",
    pregled: "Pregled",
    primjedbe: "Primjedbe",
  },
  errors: {
    noinput:
      "Potrebna polja nisu unesena. Molimo kontaktirajte administratora.",
    errorQuery:
      "Doslo je do greske tokom prikupljanja podataka. Molimo kontaktirajte administratora.",
  },
  inputFields: {
    endDate: {
      label: "Krajnji datum",
    },
    startDate: {
      label: "Pocetni datum",
    },
  },
  poljaEvidencije: {
    CIPP_V1: "Ciscenje i pranje prostora",
    CIPO_V1: "Ciscenje i pranje opreme",
    Dezinfekcija_V1: "Dezinfekcija",
    Dezinsekcija_V1: "Dezinsekcija",
    Deratizacija_V1: "Deratizacija",
    Dezinsekcija_Kontrola_V1: "Dezinsekcija Kontrola",
    Dezinskecija_Korekcija_V1: "Dezinsekcija Korekcija",
    Deratizacija_Kontrole_V1: "Deratizacija Kontrole",
    Deratizacija_Korekcije_V1: "Deratizacija Korekcije",
    DezSr_V1: "Sredstava za dezinfekciju",
    Dobavljac_V1: "Dobavljaci",
    Dostava_V1: "Dostava",
    EduZap_V1: "Edukacija zaposlenih",
    Mol_V1: "Molerski radovi",
    Oprema_V1: "Oprema",
    Otpad_V1: "Otpad",
    PrijemRobe_V1: "Prijem robe",
    San_V1: "Sanitarni pregled",
    Temperatura_V1: "Temperaturne liste",
    TemperaturaOgranicena_V1: "Temperaturne liste - ogranicena",
    TemperaturaOgranicenaKKT_V1: "Temperaturne liste - ogranicena - KKT",
    TemperaturaKKTProizvodiEvd_V1: "Temperaturne liste - proizvodi - KKT",
  },
};

export interface GetQRResourcesInterface {
  title: string;
  buttons: {
    popuni: string;
    pregled: string;
    primjedbe: string;
  };
  errors: {
    noinput: string;
    errorQuery: string;
  };
  inputFields: {
    startDate: {
      label: string;
    };
    endDate: {
      label: string;
    };
  };
  poljaEvidencije: {
    CIPP_V1: string;
    CIPO_V1: string;
    Dezinfekcija_V1: string;
    Dezinsekcija_V1: string;
    Deratizacija_V1: string;
    Dezinsekcija_Kontrola_V1: string;
    Dezinskecija_Korekcija_V1: string;
    Deratizacija_Kontrole_V1: string;
    Deratizacija_Korekcije_V1: string;
    DezSr_V1: string;
    Dobavljac_V1: string;
    Dostava_V1: string;
    EduZap_V1: string;
    Mol_V1: string;
    Oprema_V1: string;
    Otpad_V1: string;
    PrijemRobe_V1: string;
    San_V1: string;
    Temperatura_V1: string;
    TemperaturaOgranicena_V1: string;
    TemperaturaOgranicenaKKT_V1: string;
    TemperaturaKKTProizvodiEvd_V1: string;
  };
}

// http://localhost:3000/getqr?rtc=58&name=Dvoriste
