export const DOSTAVA_V1ResourcesBS: DOSTAVA_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errorMissingInput: "Molimo popunite sva polja",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  input: {
    signatureTitle: "Potpis",
    datum: {
      label: "Datum",
      placeholder: "Molimo unesite datum",
      name: "datum",
    },
    ispravnaPosiljka: {
      label: "Da li je ispravna posiljka?",
      placeholder: "Molimo unesite da li je ispravna posiljka",
      name: "ispravnaPosiljka",
    },
    dokument: {
      label: "Dokument",
      placeholder: "Molimo unesite dokument",
      name: "dokument",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
    },
    korektivnaMjera: {
      label: "Korektivna mjera",
      placeholder: "Molimo unesite korektivnu mijeru",
      name: "korektivnaMjera",
    },
    potpisPrimalac: {
      label: "Molimo potpisite dostavu",
      placeholder: "",
      name: "potpisPrimalac",
    },
    primalac: {
      label: "Ime primaoca",
      placeholder: "Molimo unesite ime primaoca",
      name: "primalac",
    },
    primjedba: {
      label: "Primjedba/Napomena",
      placeholder: "Molimo unesite primjedbu",
      name: "primjedba",
    },
    brojRacuna: {
      label: "Broj računa/otrpemnice",
      placeholder: "Molimo unesite broj računa/otrpemnice",
      name: "brojRacuna",
    },
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
  dropdownOptions: {
    da: "Da",
    ne: "Ne",
    undefined: "Molimo odaberite polje",
  },
};

interface InputField {
  label: string;
  placeholder: string;
  name: string;
  description?: () => JSX.Element; // Optional since not all fields have it
}

interface Input {
  signatureTitle: string;
  datum: InputField;
  ispravnaPosiljka: InputField;
  dokument: InputField;
  korektivnaMjera: InputField;
  potpisPrimalac: InputField;
  primalac: InputField;
  primjedba: InputField;
  brojRacuna: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IDropdownOptions {
  da: string;
  ne: string;
  undefined: string;
}

interface IErrors {
  registerPic: string;
}
export interface DOSTAVA_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  dropdownOptions: IDropdownOptions;
  errors: IErrors;
}
