export interface configInterface {
  backendUrl: string;
  frontendUrl: string;
  API_URL?: string;
  layout: ILayout;
  googleKey: string;
  googleSecret: string;
  backendUrlClean: string;
}

export interface ILayout {
  layoutType: LayoutTypes;
  layoutColor: LayoutColor;
  layoutWidth: LayoutWidth;
  menuPosition: MenuPositions;
  leftSideBarTheme: SideBarTheme;
  leftSideBarType: SideBarTypes;
  topbarTheme: TopbarTheme;
}

export enum LayoutTypes {
  LAYOUT_VERTICAL = "vertical",
  LAYOUT_HORIZONTAL = "horizontal",
  LAYOUT_DETACHED = "detached",
  LAYOUT_TWO_COLUMN = "two-column",
}

export enum LayoutColor {
  LAYOUT_COLOR_LIGHT = "light",
  LAYOUT_COLOR_DARK = "dark",
}

export enum LayoutWidth {
  LAYOUT_WIDTH_FLUID = "fluid",
  LAYOUT_WIDTH_BOXED = "boxed",
}

export enum MenuPositions {
  MENU_POSITION_FIXED = "fixed",
  MENU_POSITION_SCROLLABLE = "scrollable",
}

export enum SideBarTheme {
  LEFT_SIDEBAR_THEME_LIGHT = "light",
  LEFT_SIDEBAR_THEME_DARK = "dark",
  LEFT_SIDEBAR_THEME_BRAND = "brand",
  LEFT_SIDEBAR_THEME_GRADIENT = "gradient",
}

export enum SideBarTypes {
  LEFT_SIDEBAR_TYPE_DEFAULT = "default",
  LEFT_SIDEBAR_TYPE_CONDENSED = "condensed",
  LEFT_SIDEBAR_TYPE_COMPACT = "compact",
}

export enum TopbarTheme {
  TOPBAR_THEME_LIGHT = "light",
  TOPBAR_THEME_DARK = "dark",
}

export const config: configInterface = {
  backendUrl: "https://back.safe.ba/graphql",
  backendUrlClean: "https://back.safe.ba",
  // backendUrlClean: "http://localhost:8002",
  // backendUrl: "http://localhost:8002/graphql",
  frontendUrl: "https://app.safe.ba",
  googleKey: "6LfrxBMqAAAAAMBV0e5lHBm81_jurA5vK2MFTz3h",
  googleSecret: "6LfrxBMqAAAAAID4Dgxfj3vnTQNaj9SgwH2b96R9",
  API_URL: process.env.REACT_APP_API_URL,
  layout: {
    layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
    layoutType: LayoutTypes.LAYOUT_VERTICAL,
    layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
    leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
    leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
    menuPosition: MenuPositions.MENU_POSITION_FIXED,
    topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT,
  },
};

export default config;
