import { Navigate, Route, RouteProps } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import React from "react";
import { paths } from "./paths";

const AboutUs = React.lazy(() => import("../pages/AboutUs/AboutUs"));
const MyAccount = React.lazy(() => import("../pages/MyAccount/MyAccount"));
const ListaEvidencija = React.lazy(
  () => import("../pages/lists/ListaEvidencija/ListaEvidencija")
);

const ListaRadnogVremena = React.lazy(
  () => import("../pages/lists/ListaRadnogVremena/ListaRadnogVremena")
);

const ListaRadnogVremenaSum = React.lazy(
  () => import("../pages/lists/RadnoVrijemeSumEvd/RadnoVrijemeSumEvd")
);

const ImagePage = React.lazy(
  () => import("../pages/lists/ImagePage/ImagePage")
);

const GetQR = React.lazy(() => import("../pages/QR/GetQR/GetQR"));

const ListaKompanija = React.lazy(
  () => import("../pages/lists/ListaKompanija/ListaKompanija")
);
const EvidencijePoPotrebi = React.lazy(
  () => import("../pages/EvidencijePoPotrebi/EvidencijePoPotrebi")
);

const RadnoVrijemeEvd = React.lazy(
  () => import("../pages/RadnoVrijemeEvd/RadnoVrijemeEvd")
);

const DEZSR_V1 = React.lazy(
  () => import("../pages/Evidencije/DEZSR_V1/DEZSR_V1")
);
const DOBAVLJAC_V1 = React.lazy(
  () => import("../pages/Evidencije/DOBAVLJAC_V1/DOBAVLJAC_V1")
);
const DOSTAVA_V1 = React.lazy(
  () => import("../pages/Evidencije/DOSTAVA_V1/DOSTAVA_V1")
);
const EDUZAP_V1 = React.lazy(
  () => import("../pages/Evidencije/EDUZAP_V1/EDUZAP_V1")
);
const MOL_V1 = React.lazy(() => import("../pages/Evidencije/MOL_V1/MOL_V1"));
const OPREMA_V1 = React.lazy(
  () => import("../pages/Evidencije/OPREMA_V1/OPREMA_V1")
);
const OTPAD_V1 = React.lazy(
  () => import("../pages/Evidencije/OTPAD_V1/OTPAD_V1")
);
const PRIJEMROBE_V1 = React.lazy(
  () => import("../pages/Evidencije/PRIJEMROBE_V1/PRIJEMROBE_V1")
);
const SAN_V1 = React.lazy(() => import("../pages/Evidencije/SAN_V1/SAN_V1"));
const TEMPERATURA_V1 = React.lazy(
  () => import("../pages/Evidencije/TEMPERATURA_V1/TEMPERATURA_V1")
);
const TEMPERATURAOGRANICENA_V1 = React.lazy(
  () =>
    import(
      "../pages/Evidencije/TEMPERATURAOGRANICENA_V1/TEMPERATURAOGRANICENA_V1"
    )
);
const TEMPERATURAOGRANICENAKKT_V1 = React.lazy(
  () =>
    import(
      "../pages/Evidencije/TEMPERATURAOGRANICENAKKT_V1/TEMPERATURAOGRANICENAKKT_V1"
    )
);

const TEMPERATURAKKTPROIZVODI_V1 = React.lazy(
  () =>
    import(
      "../pages/Evidencije/TEMPERATURAKKTPROIZVODI_V1/TEMPERATURAKKTPROIZVODI_V1"
    )
);

const KonfiguracijaTemp = React.lazy(
  () => import("../pages/KatalogEvidencija/components/Step8/KonfiguracijaTemp")
);

const KonfiguracijaDeskripcije = React.lazy(
  () =>
    import(
      "../pages/KatalogEvidencija/components/Step9/KonfiguracijaDeskripcije"
    )
);

const FirstLogin = React.lazy(() => import("../pages/FirstLogin/FirstLogin"));
const Evidencije = React.lazy(() => import("../pages/Evidencije/Evidencije"));
const CIPP_V1 = React.lazy(() => import("../pages/Evidencije/CIPP_V1/CIPP_V1"));
const CIPO_V1 = React.lazy(() => import("../pages/Evidencije/CIPO_V1/CIPO_V1"));
const DEZINFEKCIJA_V1 = React.lazy(
  () => import("../pages/Evidencije/DEZINFEKCIJA_V1/DEZINFEKCIJA_V1")
);
const Obavijesti = React.lazy(
  () => import("../pages/KatalogEvidencija/components/Step6/Obavijesti")
);

const DEZINSEKCIJA_V1 = React.lazy(
  () => import("../pages/Evidencije/DEZINSEKCIJA_V1/DEZINSEKCIJA_V1")
);

const DEZINSEKCIJA_V1_KONTROLA = React.lazy(
  () =>
    import(
      "../pages/Evidencije/DEZINSEKCIJA_V1/DEZINSEKCIJA_V1_KONTROLA/DEZINSEKCIJA_V1_KONTROLA"
    )
);

const DEZINSEKCIJA_V1_KOREKCIJA = React.lazy(
  () =>
    import(
      "../pages/Evidencije/DEZINSEKCIJA_V1/DEZINSEKCIJA_V1_KOREKCIJA/DEZINSEKCIJA_V1_KOREKCIJA"
    )
);

const DERATIZACIJA_V1 = React.lazy(
  () => import("../pages/Evidencije/DERATIZACIJA_V1/DERATIZACIJA_V1")
);

const DERATIZACIJA_V1_KONTROLA = React.lazy(
  () =>
    import(
      "../pages/Evidencije/DERATIZACIJA_V1/DERATIZACIJA_V1_KONTROLA/DERATIZACIJA_V1_KONTROLA"
    )
);

const DERATIZACIJA_V1_KOREKCIJA = React.lazy(
  () =>
    import(
      "../pages/Evidencije/DERATIZACIJA_V1/DERATIZACIJA_V1_KOREKCIJA/DERATIZACIJA_V1_KOREKCIJA"
    )
);

const OdabirPoljaE = React.lazy(
  () => import("../pages/KatalogEvidencija/components/Step7/OdabirPoljaE")
);

const TPEvidencije = React.lazy(
  () => import("../pages/KatalogEvidencija/components/Step3/TPEvidencije")
);

const OdgovorneOsobe = React.lazy(
  () => import("../pages/KatalogEvidencija/components/Step5/OdgovorneOsobe")
);

const KatalogEvidencija = React.lazy(
  () => import("../pages/KatalogEvidencija/KatalogEvidencija")
);
const KatalogEvidencijaAddForm = React.lazy(
  () =>
    import("../pages/KatalogEvidencija/components/Step1/KatalogEvidencijaForm")
);
const KreiranjeEvidencije = React.lazy(
  () =>
    import("../pages/KatalogEvidencija/components/Step2/KreiranjeEvidencije")
);
const OdabirUcestalosti = React.lazy(
  () => import("../pages/KatalogEvidencija/components/Step4/OdabirUcestalosti")
);
const Visegodisnje = React.lazy(
  () =>
    import(
      "../pages/KatalogEvidencija/components/Step4/pages/Visegodisnje/Visegodisnje"
    )
);
const Godisnje = React.lazy(
  () =>
    import(
      "../pages/KatalogEvidencija/components/Step4/pages/Godisnje/Godisnje"
    )
);
const Mjesecno = React.lazy(
  () =>
    import(
      "../pages/KatalogEvidencija/components/Step4/pages/Mjesecno/Mjesecno"
    )
);
const Sedmicno = React.lazy(
  () =>
    import(
      "../pages/KatalogEvidencija/components/Step4/pages/Sedmicno/Sedmicno"
    )
);
const Dnevno = React.lazy(
  () =>
    import("../pages/KatalogEvidencija/components/Step4/pages/Dnevno/Dnevno")
);
const DodavanjeKorisnika = React.lazy(
  () =>
    import("../pages/DodavanjeKorisnika/DodavanjeKorisnika/DodavanjeKorisnika")
);
const UpravljanjeKorisnicima = React.lazy(
  () =>
    import(
      "../pages/DodavanjeKorisnika/UpravljanjeKorisnicima/UpravljanjeKorisnicima"
    )
);
const DKOK = React.lazy(
  () =>
    import(
      "../pages/DodavanjeKorisnika/DodavanjeKorisnika/components/DKOK/DKOK"
    )
);
const DKOP = React.lazy(
  () =>
    import(
      "../pages/DodavanjeKorisnika/DodavanjeKorisnika/components/DKOP/DKOP"
    )
);

const OdabirKompanijeKorisnika = React.lazy(
  () =>
    import(
      "../pages/DodavanjeKorisnika/UpravljanjeKorisnicima/components/UKPPK/UKPPK"
    )
);

const IzmjenaPoslovnice = React.lazy(
  () => import("../pages/RegistracijaKompanije/IzmjenaPoslovnice")
);
const OdabirKompanije = React.lazy(
  () => import("../pages/RegistracijaKompanije/OdabirKompanije")
);
const OdabirPoslovnice = React.lazy(
  () => import("../pages/RegistracijaKompanije/OdabirPoslovnice")
);
const RegistracijaKompanije = React.lazy(
  () => import("../pages/RegistracijaKompanije/RegistracijaKompanije")
);
const RegistracijaPoslovnice = React.lazy(
  () => import("../pages/RegistracijaKompanije/RegistracijaPoslovnice")
);
const OdabirKompanijeTP = React.lazy(
  () => import("../pages/TackePrimjene/OdabirKompanijeTP")
);
const OdabirPoslovniceTP = React.lazy(
  () => import("../pages/TackePrimjene/OdabirPoslovniceTP")
);
const IzmjenaTP = React.lazy(() => import("../pages/TackePrimjene/IzmjenaTP"));

// import Root from './Root';

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// dashboard
const Dashboard1 = React.lazy(
  () => import("../pages/dashboard/Dashboard1/Dashboard")
);

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/dashboard-1" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard-1",
      name: "Dashboard 1",
      element: <Dashboard1 />,
      route: PrivateRoute,
    },
  ],
};

const registracijaKompanijeRoute = {
  path: "/register",
  name: "RegistracijaKompanije",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: paths.register.company,
      name: "Odabir Kompanije",
      element: <OdabirKompanije />,
      route: PrivateRoute,
    },
    {
      path: paths.register.branch,
      name: "Odabir Poslovnice",
      element: <OdabirPoslovnice />,
      route: PrivateRoute,
    },
    {
      path: paths.register.editBranch,
      name: "Izmjena Poslovnice",
      element: <IzmjenaPoslovnice />,
      route: PrivateRoute,
    },
    {
      path: paths.register.addCompany,
      name: "Registracija Kompanije",
      element: <RegistracijaKompanije />,
      route: PrivateRoute,
    },
    {
      path: paths.register.addBranch,
      name: "Registracija Poslovnice",
      element: <RegistracijaPoslovnice />,
      route: PrivateRoute,
    },
  ],
};

const tackePrimjeneRoute = {
  path: "/record-point",
  name: "RegistracijaKompanije",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: paths.recordPoint.company,
      name: "Tacke Primjene",
      element: <OdabirKompanijeTP />,
      route: PrivateRoute,
    },
    {
      path: paths.recordPoint.branch,
      name: "Odabir Poslovnice",
      element: <OdabirPoslovniceTP />,
      route: PrivateRoute,
    },
    {
      path: paths.recordPoint.editRecordPoint,
      name: "Izmjena Tacke Primjene",
      element: <IzmjenaTP />,
      route: PrivateRoute,
    },
  ],
};

const dodavanjeKorisnikaRoute = {
  path: "/users",
  name: "DodavanjeKorisnika",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: paths.addUsers.addUsers,
      name: "Dodavanje Korisnika",
      element: <DodavanjeKorisnika />,
      route: PrivateRoute,
    },
    {
      path: paths.addUsers.editUsers,
      name: "Upravljanje Korisnicima",
      element: <UpravljanjeKorisnicima />,
      route: PrivateRoute,
    },
    {
      path: paths.addUsers.viewUsersData,
      name: "Prikaz podataka korisnika",
      element: <OdabirKompanijeKorisnika />,
      route: PrivateRoute,
    },
    {
      path: paths.addUsers.dkok,
      name: "Odabir Kompanije",
      element: <DKOK />,
      route: PrivateRoute,
    },
    {
      path: paths.addUsers.dkop,
      name: "Odabir Poslovnice",
      element: <DKOP />,
      route: PrivateRoute,
    },
  ],
};

const KatalogEvidencijaRoute = {
  path: "/records-settings",
  name: "Katalog Evidencija",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: paths.recordsSettings.recordsSettings,
      name: "Katalog Evidencija",
      element: <KatalogEvidencija />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsAddForm,
      name: "Katalog Evidencija Form",
      element: <KatalogEvidencijaAddForm />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsAddRecord,
      name: "Kreiranje Evidencije",
      element: <KreiranjeEvidencije />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsFrequencySelection,
      name: "Odabir Ucestalosti",
      element: <OdabirUcestalosti />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.visegodisnje,
      name: "Visegodisnje",
      element: <Visegodisnje />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.godisnje,
      name: "Godisnje",
      element: <Godisnje />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.mjesecno,
      name: "Mjesecno",
      element: <Mjesecno />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.sedmicno,
      name: "Sedmicno",
      element: <Sedmicno />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.dnevno,
      name: "Dnevno",
      element: <Dnevno />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsRecordPoints,
      name: "Tacke Primjene Evidencije",
      element: <TPEvidencije />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.obavijesti,
      name: "Obavijesti",
      element: <Obavijesti />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsResponsiblePerson,
      name: "Odgovorne Osobe",
      element: <OdgovorneOsobe />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordFieldSelection,
      name: "Odabir Polja Evidencije",
      element: <OdabirPoljaE />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.evidencijePoPotrebi,
      name: "Evidencije Po Potrebi",
      element: <EvidencijePoPotrebi />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsTempConfig,
      name: "Records Settings - Temp Config",
      element: <KonfiguracijaTemp />,
      route: PrivateRoute,
    },
    {
      path: paths.recordsSettings.recordsSettingsDescription,
      name: "Records Settings - Description Config",
      element: <KonfiguracijaDeskripcije />,
      route: PrivateRoute,
    },
  ],
};

const listsRoute = {
  path: "/lists",
  name: "Liste",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: paths.lists.listaEvidencija,
      name: "Lista Evidencija",
      element: <ListaEvidencija />,
      route: PrivateRoute,
    },
    {
      path: paths.lists.listaKompanija,
      name: "Lista Kompanija",
      element: <ListaKompanija />,
      route: PrivateRoute,
    },
    {
      path: paths.lists.listaRadnoVrijeme,
      name: "Lista Radno Vrijeme",
      element: <ListaRadnogVremena />,
      route: PrivateRoute,
    },
    {
      path: paths.lists.imagePage,
      name: "Image Page",
      element: <ImagePage />,
      route: PrivateRoute,
    },
    {
      path: paths.lists.listaRadnoVrijemeSum,
      name: "Radno Vrijeme Sum",
      element: <ListaRadnogVremenaSum />,
      route: PrivateRoute,
    },
  ],
};

const EvidencijeRoute = {
  path: "/records",
  name: "Evidencije",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: paths.records.evidencije,
      name: "Evidencije",
      element: <Evidencije />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsCIPPv1,
      name: "CIPP_V1",
      element: <CIPP_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsCIPOv1,
      name: "CIPO_V1",
      element: <CIPO_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDezinfekcijav1,
      name: "DEZINFEKCIJA_V1",
      element: <DEZINFEKCIJA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDeratizacijav1,
      name: "DERATIZACIJA_V1",
      element: <DERATIZACIJA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDeratizacijav1Kontrola,
      name: "DERATIZACIJA_V1_KONTROLA",
      element: <DERATIZACIJA_V1_KONTROLA />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDeratizacijav1Korekcija,
      name: "DEZINFEKCIJA_V1_KOREKCIJA",
      element: <DERATIZACIJA_V1_KOREKCIJA />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDezinsekcijav1,
      name: "DEZINSEKCIJA_V1",
      element: <DEZINSEKCIJA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDezinsekcijav1Kontrola,
      name: "DEZINSEKCIJA_V1_KONTROLA",
      element: <DEZINSEKCIJA_V1_KONTROLA />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDezinsekcijav1Korekcija,
      name: "DEZINSEKCIJA_V1_KOREKCIJA",
      element: <DEZINSEKCIJA_V1_KOREKCIJA />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDezSr,
      name: "DEZSR_V1",
      element: <DEZSR_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDobavljac,
      name: "DOBAVLJAC_V1",
      element: <DOBAVLJAC_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsDostava,
      name: "DOSTAVA_V1",
      element: <DOSTAVA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsEduZap,
      name: "EDUZAP_V1",
      element: <EDUZAP_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsMol,
      name: "MOL_V1",
      element: <MOL_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsOprema,
      name: "OPREMA_V1",
      element: <OPREMA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsOtpad,
      name: "OTPAD_V1",
      element: <OTPAD_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsPrijemRobe,
      name: "PRIJEMROBE_V1",
      element: <PRIJEMROBE_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsSan,
      name: "SAN_V1",
      element: <SAN_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsTemperatura,
      name: "TEMPERATURA_V1",
      element: <TEMPERATURA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsTemperaturaOgranicena,
      name: "TEMPERATURAOGRANICENA_V1",
      element: <TEMPERATURAOGRANICENA_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsTemperaturaOgranicenaKKT,
      name: "TEMPERATURAOGRANICENAKKT_V1",
      element: <TEMPERATURAOGRANICENAKKT_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.recordsTemperaturaKKTProizvodi,
      name: "TEMPERATURAKKTPROIZVODI_V1",
      element: <TEMPERATURAKKTPROIZVODI_V1 />,
      route: PrivateRoute,
    },
    {
      path: paths.records.radnoVrijemeEvd,
      name: "RADNOVRIJEMEEVD",
      element: <RadnoVrijemeEvd />,
      route: PrivateRoute,
    },
  ],
};

const appRoutes = [
  dodavanjeKorisnikaRoute,
  registracijaKompanijeRoute,
  tackePrimjeneRoute,
  KatalogEvidencijaRoute,
  EvidencijeRoute,
];

// pages
const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      element: <Error404Alt />,
      route: PrivateRoute,
    },
    {
      path: paths.auth.firstLogin,
      name: "First Login",
      element: <FirstLogin />,
      route: Route,
    },
    {
      path: paths.myaccount,
      name: "My Account",
      element: <MyAccount />,
      route: Route,
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: paths.auth.login,
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: paths.auth.register,
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: paths.auth.confirm,
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: paths.auth.forgetPassword,
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: paths.auth.signUp,
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: paths.auth.lockScreen,
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: paths.auth.logout,
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: paths.errors.error404,
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    element: <Error404Two />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    element: <Error500Two />,
    route: Route,
  },
];

const styledPublicPages = [
  {
    path: paths.aboutus,
    name: "About Us",
    element: <AboutUs />,
    route: Route,
  },
  {
    path: paths.getqr,
    name: "Get QR Page",
    element: <GetQR />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  listsRoute,
];

const publicRoutes = [...authRoutes, ...otherPublicRoutes];
const styledPublicRoutes = flattenRoutes([...styledPublicPages]);
const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  styledPublicRoutes,
};
