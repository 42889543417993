export const MOL_V1ResourcesBS: MOL_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errorMissingInput: "Molimo popunite sva polja",
  input: {
    signatureTitle: "Potpis",
    datum: {
      label: "Datum",
      placeholder: "Molimo unesite datum",
      name: "datum",
    },
    opis: {
      label: "Opis",
      placeholder: "Molimo unesite opis",
      name: "opis",
    },
    izvrsioc: {
      label: "Izrvrsioc radova",
      placeholder: "Molimo unesite izvrsioca radova",
      name: "izvrsioc",
    },
    potpisIzvrsioca: {
      label: "Potpis izvrsioca radova",
    },
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface InputField {
  label: string;
  placeholder?: string; // Optional since not all fields may have it
  name?: string; // Optional since not all fields may have it
}

interface Input {
  signatureTitle: string;
  datum: InputField;
  opis: InputField;
  izvrsioc: InputField;
  potpisIzvrsioca: InputField;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

export interface MOL_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
}
