import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    mainColor: string;
    secondColor: string;
    dirtyWhite: string;
  }
}

export const theme: DefaultTheme = {
  mainColor: "#161B21",
  secondColor: "#117CD9",
  dirtyWhite: "FFFFCC",
};

export function hexToRGBA(hex: string, alpha = 1) {
  let r = parseInt(hex.slice(1, 3), 16); // Red
  let g = parseInt(hex.slice(3, 5), 16); // Green
  let b = parseInt(hex.slice(5, 7), 16); // Blue

  return `rgba(${r}, ${g}, ${b}, ${alpha})`; // Return RGBA color with alpha
}
