export const OPREMA_V1ResourcesBS: OPREMA_V1ResourcesBSInterface = {
  input: {
    datum: {
      name: "datum",
      label: "Datum obavljanja zadatka",
      placeholder: "Unesite datum",
    },
    tip: {
      name: "tip",
      label: "Molimo unesite tip",
      placeholder: "Unesite tip",
    },
    izvrsioc: {
      name: "izvrsioc",
      label: "Molimo unesite ime izvrsioca",
      placeholder: "Unesite ime izvrsioca",
    },
    opis: {
      name: "opis",
      label: "Molimo unesite opis",
      placeholder: "Unesite opis",
    },
  },
  type: {
    EvdLabel: "Tip evidencije",
    Name: "type",
    Placeholder:
      "Upisuje se: Redovno, vanredno, generalno, sedmicno, mjesecno, godisnje...",
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface InputField {
  name: string;
  label: string;
  placeholder: string;
}

interface Input {
  datum: InputField;
  tip: InputField;
  izvrsioc: InputField;
  opis: InputField;
}

interface Type {
  EvdLabel: string;
  Name: string;
  Placeholder: string;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

export interface OPREMA_V1ResourcesBSInterface {
  input: Input;
  type: Type;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
}
