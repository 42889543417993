import { ITextFields } from "./components/Tables";

export const ListaEvidencijaResourcesBS: ListaEvidencijaResourcesBSInterface = {
  title: "Evidencije",
  listaTacakaEvidencije: "Prikaz evidencija po tačkama",
  listaEvidencija: "Prikaz evidencija",
  branchButton: "Odaberi filijalu",
  errorLoading: "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
  next: "Naprijed",
  back: "Nazad",
  from: "Od datuma: ",
  to: "Do datuma: ",
  branch: {
    placeholder: "Odabir poslovnice",
    notFound: "Ni jedna poslovnica nije pronadjena",
  },
  evidencije: {
    title: "Popunjene evidencije odabranog perioda:",
    pojedinacneDownload: "Pojedinačna evidencija PDF",
    zbirniDownload: "Zbirna evidencija PDF",
  },
  pdf: {
    right: {
      title: "Odabrani period: ",
      from: "Od: ",
      to: "Do: ",
    },
    center: {
      title: "Evidencija:",
    },
  },
  poljaEvidencije: {
    CIPP_V1: "Ciscenje i pranje prostora",
    CIPO_V1: "Ciscenje i pranje opreme",
    Dezinfekcija_V1: "Dezinfekcija",
    Dezinsekcija_V1: "Dezinsekcija",
    Deratizacija_V1: "Deratizacija",
    Dezinsekcija_Kontrola_V1: "Dezinsekcija Kontrola",
    Dezinskecija_Korekcija_V1: "Dezinsekcija Korekcija",
    Deratizacija_Kontrole_V1: "Deratizacija Kontrole",
    Deratizacija_Korekcije_V1: "Deratizacija Korekcije",
    DezSr_V1: "Sredstava za dezinfekciju",
    Dobavljac_V1: "Dobavljaci",
    Dostava_V1: "Dostava",
    EduZap_V1: "Edukacija zaposlenih",
    Mol_V1: "Molerski radovi",
    Oprema_V1: "Oprema",
    Otpad_V1: "Otpad",
    PrijemRobe_V1: "Prijem robe",
    San_V1: "Sanitarni pregled",
    Temperatura_V1: "Temperaturne liste",
    TemperaturaOgranicena_V1: "Temperaturne liste - ogranicena",
    TemperaturaOgranicenaKKT_V1: "Temperaturne liste - ogranicena - KKT",
    TemperaturaKKTProizvodiEvd_V1: "Temperaturne liste - proizvodi - KKT",
  },
  pdfListaEvidencija: {
    ispravnaPosiljka: "Ispravna posiljka",
    da: "Da",
    ne: "Ne",
    proizvod: "Proizvod",
    brojRacuna: "Broj računa/otrpemnice",
    otpadKorektivnaMjera: "Adekvatno prikupljen otpad",
    tackaPrimjene: "Tačka evidencije",
    donjaGranicaHeader: "Donja granica",
    gornjaGranicaHeader: "Gornja granica",
    rasponHeader: "Raspon",
    odabraniPeriod: "Odabrani period",
    evidencija: "Evidencija",
    period: "Odabrani period: ",
    datum: "Datum i vrijeme",
    imeIPrezime: "Ime i prezime",
    imePrezime: "Ime i prezime",
    potpis: "Potpis",
    naziv: "Naziv",
    tipEvidencije: "Tip Evidencije",
    slikaDeklaracije: "Slika Deklaracije",
    opisKontrole: "Opis Kontrole",
    stanjeKontrole: "Infestacija glodarima",
    opisKorekcije: "Opis Korekcije",
    stanjeKorekcije: "Stanje Korekcije",
    primjena: "Primjena",
    upustvoZaUpotrebu: "Upustvo za upotrebu",
    opis: "Opis",
    vrsta: "Vrsta",
    slika: "Slika",
    address: "Adresa",
    phoneNumber: "Telefon",
    idTax: "ID broj",
    pdvTax: "PDV broj",
    productType: "Vrsta robe",
    posiljalac: "Dostavljač",
    potpisPosiljalac: "Potpis dostavljača",
    primalac: "Primalac",
    potpisPrimalac: "Potpis primalac",
    dokument: "Dokument",
    primjedba: "Primjedba/Napomena",
    korektivnaMjera: "Korektivna mjera",
    datumObuke: "Datum obuke",
    mjestoObuke: "Mjesto obuke",
    tipObuke: "Tip obuke",
    izvrsilacObuke: "Izvrsilac obuke",
    tema: "Tema",
    cilj: "Cilj",
    verifikacijaEfikasnosti: "Verifikacija efikasnosti",
    radnoMjesto: "Radno mjesto",
    verifikacijaPrisustvaPotpis: "Verifikacija prisustva - potpis",
    verifikacijaEdukatoraPotpis: "Verifikacija edukatora - potpis",
    izvrsioc: "Izvršio",
    potpisIzvrsioca: "Potpis izvršioca",
    uposlenik: "Uposlenik",
    vaziOd: "Vazi od",
    vaziDo: "Vazi do",
    tip: "Tip",
    vrstaOtpada: "Vrsta otpada",
    otpadPredao: "Otpad predao",
    otpadPrimio: "Otpad primio",
    kolicina: "Kolicina / jedinica mjere",
    primaoc: "primaoc",
    ocitanaTemperatura: "Očitana temperatura",
    dozvoljenaTempMin: "Dozvoljena minimalna temperatura",
    dozvoljenaTempMax: "Dozvoljena maksimalna temperatura",
    dozvoljenoOdstupanje: "Dozvoljeno odstupanje",
    stanje: "Infestacija insektima",
    opisMjeereKorekcije: "Opis mjere korekcije",
    ocitanaTemperaturaKorekcije: "Očitana temperatura korekcije",
    dozvoljenaTempMinKorekcije: "Dozvoljena min. temperatura korekcije",
    dozvoljenaTempMaxKorekcije: "Dozvoljena maks. temperatura korekcije",
    imePrezimeKorekcije: "Ime i prezime izvrsioca korekcije",
    potpisIzvrsiocaKorekcije: "Potpis izvrsioca korekcije",
    datumKorekcije: "Datum korekcije",
    vrijemeKorekcije: "Vrijeme korekcije",
    zadovoljenaKontrola: "Dozvoljena temperatura",
    napomena: "Napomena",
    upisaoFooter: "Kontrolisao/Odobrio",
    potpisFooter: "Potpis",
    kontrolisaoPrimioRadove: "Kontrolisao/primio radove",
  },
};

export interface ListaEvidencijaResourcesBSInterface {
  title: string;
  listaTacakaEvidencije: string;
  listaEvidencija: string;
  errorLoading: string;
  branchButton: string;
  next: string;
  back: string;
  from: string;
  to: string;
  branch: {
    placeholder: string;
    notFound: string;
  };
  evidencije: {
    title: string;
    pojedinacneDownload: string;
    zbirniDownload: string;
  };
  pdf: {
    right: {
      title: string;
      from: string;
      to: string;
    };
    center: {
      title: string;
    };
  };
  poljaEvidencije: {
    CIPP_V1: string;
    CIPO_V1: string;
    Dezinfekcija_V1: string;
    Dezinsekcija_V1: string;
    Deratizacija_V1: string;
    Dezinsekcija_Kontrola_V1: string;
    Dezinskecija_Korekcija_V1: string;
    Deratizacija_Kontrole_V1: string;
    Deratizacija_Korekcije_V1: string;
    DezSr_V1: string;
    Dobavljac_V1: string;
    Dostava_V1: string;
    EduZap_V1: string;
    Mol_V1: string;
    Oprema_V1: string;
    Otpad_V1: string;
    PrijemRobe_V1: string;
    San_V1: string;
    Temperatura_V1: string;
    TemperaturaOgranicena_V1: string;
    TemperaturaOgranicenaKKT_V1: string;
    TemperaturaKKTProizvodiEvd_V1: string;
  };
  pdfListaEvidencija: ITextFields;
}
