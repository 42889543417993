export const MyAccountResourcesBS: MyAccountResourcesInterface = {
  title: "My Account",
  nextButton: "Naprijed",
  backButton: "Nazad",
  signatureTitle: "Potpis",
  errorMissingInput: "Molimo popunite sva polja",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  profilePicture: {
    label: "Profile Picture",
    name: "profilePicture",
    error: "Please select your profile picture",
    validation: "Wrong profile picture",
    description: () => (
      <>
        <h4> files here or click to upload.</h4>
        <span className="text-muted font-13">Only images, max 4MB</span>
      </>
    ),
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  errorPasswordMatch: "Sifre se ne podudaraju.",
  password: {
    label: "Sifra",
    placeholder: "Molimo unesite sifru",
  },
  passwordRepeat: {
    label: "Ponovite sifru",
    placeholder: "Molimo ponovite sifru",
  },
};

interface IErrors {
  registerPic: string;
}

export interface MyAccountResourcesInterface {
  title: string;
  nextButton: string;
  backButton: string;
  signatureTitle: string;
  errorMissingInput: string;
  profilePicture: {
    label: string;
    name: string;
    error: string;
    validation: string;
    description: () => JSX.Element;
  };
  errorPasswordMatch: string;
  password: {
    label: string;
    placeholder: string;
  };
  passwordRepeat: {
    label: string;
    placeholder: string;
  };
  successModal: {
    title: string;
    body: string;
    buttonOk: string;
  };
  errors: IErrors;
}
