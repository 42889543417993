export const paths = {
  home: "/",
  aboutus: "/aboutus",
  getqr: "/getqr",
  myaccount: "/myaccount",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    confirm: "/auth/confirm",
    forgetPassword: "/auth/forget-password",
    signUp: "/auth/signin-signup",
    lockScreen: "/auth/lock-screen",
    logout: "/auth/logout",
    firstLogin: "/auth/first-login",
  },
  errors: {
    error404: "/error-404",
  },
  register: {
    company: "/register/company",
    branch: "/register/branch",
    editBranch: "/register/editbranch",
    addCompany: "/register/addcompany",
    addBranch: "/register/addbranch",
  },
  recordPoint: {
    company: "/record-point/company",
    branch: "/record-point/branch",
    editRecordPoint: "/record-point/editrecordpoint",
  },
  addUsers: {
    addUsers: "/users/add",
    dkok: "/users/company",
    dkop: "/users/branch",
    editUsers: "/users/edit",
    viewUsersData: "/users/data",
    usersData: "/users/data",
  },
  users: {
    users: "/users",
  },
  recordsSettings: {
    recordsSettings: "/record-settings",
    recordsSettingsAddForm: "/record-settings-addform",
    recordsSettingsAddRecord: "/record-settings-addrecord",
    recordsSettingsTempConfig: "/record-settings-tempconfig",
    recordsSettingsFrequencySelection: "/record-settings-frequency",
    visegodisnje: "/record-settings-frequency/visegodisnje",
    godisnje: "/record-settings-frequency/godisnje",
    mjesecno: "/record-settings-frequency/mjesecno",
    sedmicno: "/record-settings-frequency/sedmicno",
    dnevno: "/record-settings-frequency/dnevno",
    recordsSettingsRecordPoints: "/record-settings-record-points",
    obavijesti: "/record-settings-notifications",
    recordsSettingsResponsiblePerson: "/record-settings-responsible-person",
    recordFieldSelection: "/record-settings-field-selection",
    evidencijePoPotrebi: "/optional-records",
    recordsSettingsDescription: "/record-settings-description",
  },
  qrCodes: {
    qrCodes: "/qr-codes",
  },
  records: {
    dashboard: "/dashboard-1",
    evidencije: "/evidencije",
    recordsCIPPv1: "/record-cipp-v1",
    recordsCIPOv1: "/record-cipo-v1",
    recordsDezinfekcijav1: "/record-dezinfekcija-v1",
    recordsDezinsekcijav1: "/record-dezinsekcija-v1",
    recordsDezinsekcijav1Kontrola: "/record-dezinsekcija-v1-kontrola",
    recordsDezinsekcijav1Korekcija: "/record-dezinsekcija-v1-korekcija",
    recordsDeratizacijav1: "/record-deratizacija-v1",
    recordsDeratizacijav1Kontrola: "/record-deratizacija-v1-kontrola",
    recordsDeratizacijav1Korekcija: "/record-deratizacija-v1-korekcija",
    recordsDezSr: "/record-dezsr-v1",
    recordsDobavljac: "/record-dobavljac-1",
    recordsDostava: "/record-dostava-v1",
    recordsEduZap: "/record-eduzap-v1",
    recordsMol: "/record-mol-v1",
    recordsOprema: "/record-oprema-v1",
    recordsOtpad: "/record-otpad-v1",
    recordsPrijemRobe: "/record-prijemrobe-v1",
    recordsSan: "/record-san-v1",
    recordsTemperatura: "/record-temperatura-v1",
    recordsTemperaturaOgranicena: "/record-temperatura-ogranicena-v1",
    recordsTemperaturaOgranicenaKKT: "/record-temperatura-ogranicena-kkt-v1",
    recordsTemperaturaKKTProizvodi: "/record-temperatura-kkt-proizvodi-v1",
    radnoVrijemeEvd: "/record-radno-vrijeme-evd",
  },
  lists: {
    listaKompanija: "/lista-kompanija",
    listaEvidencija: "/lista-evidencija",
    listaRadnoVrijeme: "/lista-radno-vrijeme",
    listaRadnoVrijemeSum: "/lista-radno-vrijeme-sum",
    imagePage: "/image-page",
  },
};
