export const KatalogEvidencijaPoPotrebiResourcesBS: KatalogEvidencijaPoPotrebiResourcesBSInterface =
  {
    title: "Evidencije po potrebi",
    errorLoading:
      "Greska tokom lodiranja. Molimo kontaktirajte administratora.",
    placeholder: "Evidencije nisu pronadjene.",
  };

// TODO: Translate json
export const JSON_VRSTE_EVIDENCIJE = [
  { value: "TEMPLATE", label: "Template" },
  { value: "EVIDENCIJA", label: "Evidencija" },
];

export const JSON_TYPE_EVIDENCIJE = [
  {
    value: "REGULAR",
    label: "Redovna",
  },
  { value: "OCCASIONALLY", label: "Po potrebi" },
];

export interface KatalogEvidencijaPoPotrebiResourcesBSInterface {
  title: string;
  errorLoading: string;
  placeholder: string;
}
