function getFirstDayOfCurrentYear() {
  const currentYear = new Date().getFullYear();
  return `${currentYear}-01-01T00:01`;
}

function getLastDayOfJanuaryCurrentYear() {
  const currentYear = new Date().getFullYear();
  return `${currentYear}-01-31T23:59`;
}

function findFirstMondayOfYearFormatted(): string {
  const currentYear: number = new Date().getFullYear(); // Get the current year
  const janFirst: Date = new Date(currentYear, 0, 1); // January 1st of the current year
  const dayOfWeek: number = janFirst.getDay(); // Get the day of the week for Jan 1st (0 for Sunday, 1 for Monday, etc.)

  // Adjust the date to the first Monday of the year if January 1st is not a Monday
  if (dayOfWeek !== 1) {
    janFirst.setDate(
      janFirst.getDate() + (dayOfWeek === 0 ? 1 : 8 - dayOfWeek)
    );
  }

  // Set time to 00:00:00.000 and adjust for timezone
  janFirst.setHours(0, 0, 0, 0);
  janFirst.setHours(janFirst.getHours() - janFirst.getTimezoneOffset() / 60);

  // Add one extra day to the calculated Monday
  janFirst.setDate(janFirst.getDate());

  return janFirst.toISOString().substring(0, 19); // Format to YYYY-MM-DDTHH:MM:SS
}

function findFirstFridayOfYearFormatted(): string {
  const mondayDate: Date = findFirstMondayOfYear();
  mondayDate.setDate(mondayDate.getDate() + 5); // Add 4 days to get to Sunday of the same week

  // Set time to 00:00:00.000 and adjust for timezone
  mondayDate.setHours(23, 59, 0, 0);
  mondayDate.setHours(
    mondayDate.getHours() - mondayDate.getTimezoneOffset() / 60
  );

  return mondayDate.toISOString().substring(0, 19); // Format to YYYY-MM-DDTHH:MM:SS
}

// Helper function to get the Date object for the first Monday
function findFirstMondayOfYear(): Date {
  const currentYear: number = new Date().getFullYear(); // Get the current year
  const janFirst: Date = new Date(currentYear, 0, 1); // January 1st of the current year
  const dayOfWeek: number = janFirst.getDay(); // Get the day of the week for Jan 1st (0 for Sunday, 1 for Monday, etc.)

  // Adjust the date to the first Monday of the year if January 1st is not a Monday
  if (dayOfWeek !== 1) {
    janFirst.setDate(
      janFirst.getDate() + (dayOfWeek === 0 ? 1 : 8 - dayOfWeek)
    );
  }

  // Set time to 00:00:00.000 and adjust for timezone
  janFirst.setHours(0, 0, 0, 0);
  janFirst.setHours(janFirst.getHours() - janFirst.getTimezoneOffset() / 60);

  // Adding extra day here for consistency in use by Friday function
  janFirst.setDate(janFirst.getDate() + 1);

  return janFirst;
}

function getCurrentYear(): number {
  const currentDate: Date = new Date(); // Create a new Date object for the current date
  return currentDate.getFullYear(); // Return the full year from the Date object
}

const config = {
  API_URL: process.env.REACT_APP_API_URL,
  DATES_EVIDENCIJE: {
    YEARMIN: getFirstDayOfCurrentYear(),
    YEARMAX: "2050-01-31T23:59",
    MONTHMAX: getLastDayOfJanuaryCurrentYear(),
    DATUMFORMAT: "MM.DD.YYYY HH:mm",
    SEDMICNA: {
      UCESTALOSTMIN: getCurrentYear() + "-01",
      UCESTALOSTMAX: getCurrentYear() + "-12",
      MINWEEK: findFirstMondayOfYearFormatted(),
      MAXWEEK: findFirstFridayOfYearFormatted(),
    },
  },
  encrpytionKey: "ab12cd34ef56gh78ij90kl01mn23op45",
};

export default config;
