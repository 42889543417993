import {
  EnumAlarmType,
  EnumNotificationType,
  EnumTempDeterminer,
} from "../../redux/interfaces";

export const KatalogEvidencijaResourcesBS: KatalogEvidencijaResourcesBSInterface =
  {
    deleteModal: {
      title: "",
      text: "Do you want to delete the record point?",
      buttonYes: "Delete",
      buttonNo: "Back",
    },
    errors: {
      poslovnica: "Molimo odaberite poslovnicu.",
      katalogEvidencijaBranches:
        "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
      deleteError:
        "Doslo je do greske tokom brisanja konfiguracije. Molimo kontaktirajte administratora.",
    },
    successModal: {
      title: "Unos podataka",
      body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
      buttonOk: "OK",
    },
    editDescription: {
      title: "Obrada opisa",
    },
    addButton: "Dodaj novi",
    evidencijePlaceholder: "Evidencije nisu pronadjene",
    evidencijeOdabirPoslovnice: "Izaberite poslovnicu",
    typeEvidencije: {
      occasionally: "Po potrebi",
      redovna: "Redovna",
      placeholder: "Molimo odaberite kategoriju",
    },
    vrsteEvidencije: {
      evidencija: "Evidencija",
      template: "Template",
    },
    evidencijaForm: {
      title: "Katalog Evidencija Form",
      branch: {
        label: "Odaberite poslovnicu",
        placeholder: "Odaberite poslovnicu",
        noOptions: "Poslovnice nisu pronadjene",
        name: "branch",
        error: "",
      },
      typeList: {
        label: "Odaberite kategoriju",
        placeholder: "Odaberite kategoriju",
        noOptions: "Kategorije nisu pronadjene",
        name: "typeList",
        error: "",
      },
      revizija: {
        label: "Revizija",
        placeholder: "Dodajte verziju revizije",
        name: "revizija",
        error: "Dodajte verziju revizije",
      },
      description: {
        label: "Opis",
        placeholder: "Dodajte opis",
        name: "description",
        error: "Dodajte opis",
      },
      titleForm: {
        label: "Naziv evidencije",
        placeholder: "Dodajte naziv evidencije",
        name: "title",
        error: "Dodajte naziv evidencije",
      },
      category: {
        label: "Odaberite tip evidencije",
        placeholder: "Odaberite tip",
        noOptions: "Tipovi nisu pronadjeni",
        name: "category",
        error: "",
      },
      buttonForward: "Dodaj",
      buttonBack: "Nazad",
      successModal: {
        title: "Unos podataka",
        body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
        buttonOk: "OK",
      },
    },
    kreiranjeEvidencije: {
      errorMissingFields:
        "Molimo popunite sva polja, da bi mogli aktivirati evidenciju.",
      errorCreation: "Doslo je do greske. Molimo kontaktirajte administratora.",
      description: {
        label: "Opisi",
        placeholder: "Unesite opis",
        name: "opis",
        error: "",
      },
      tempConfigPolja: {
        name: "tempConfig",
        label: "Konfiguracija temperature",
        placeholder: "Molimo unesite konfiguraciju temperature",
        title: "Konfiguracija Temperature",
        dozvoljenaTempMin: {
          label: "Minimalna dozvoljena temperatura",
          name: "dozvoljenaTempMin",
          placeholder: "Molimo unesite minimalnu temperaturu",
        },
        dozvoljenaTempMax: {
          label: "Maksimalna dozvoljena temperatura",
          name: "dozvoljenaTempMax",
          placeholder: "Molimo unesite ocitanu temperaturu",
        },
        dozvoljenoOdstupanje: {
          label: "Dozvoljeno odstupanje",
          name: "dozvoljenoOdstupanje",
          placeholder: "Molimo unesite dozvoljeno odstupanje",
        },
      },
      status: {
        activate: "Aktiviraj",
        deactivate: "Deaktiviraj",
        saveAs: "Nova verzija",
      },
      nazivEvidencije: {
        title: "Kreiranje Evidencije",
        label: "Naziv evidencije",
        placeholder: "Dodajte naziv evidencije",
        name: "title",
        error: "Dodajte naziv evidencije",
      },
      revizija: {
        title: "Revizija",
        label: "Revizija",
        placeholder: "Dodajte reviziju",
        name: "revizija",
        error: "Dodajte reviziju",
      },
      tackePrimjene: {
        title: "Tacke primjene",
        label: "Tacke primjene",
        placeholder: "Odaberite tacke primjene",
        name: "recordPoints",
        error: "Nije odabrana tacka primjene",
      },
      ucestalost: {
        title: "Ucestalost Evidentiranja",
        label: "Ucestalost Evidentiranja",
        placeholder: "Odaberite ucestalost evidentiranja",
        name: "frequency",
        error: "Nije odabrana ucestalost evidentiranja",
        visegodisnje: {
          title: "Višegodišnje",
          btnVisegodisnje: "Višegodišnje",
          btnDatum: "Datum",
        },
        godisnje: {
          title: "Godišnje",
          btnGodisnje: "Godišnje",
          btnDatum: "Datum",
        },
        mjesecno: {
          title: "Mjesecno",
          btnMjesecno: "Mjesecno",
          btnDatumPocetak: "Datum početak",
          btnDatumKraj: "Datum završetak",
          btnBrojanje: "Početak od",
          lblSvaki: "Svaki ",
          lblMjesec: "mjesec",
        },
        sedmicno: {
          title: "Sedmicno",
          btnSedmicno: "Sedmicno",
          btnDatumPocetak: "Datum početak",
          btnDatumKraj: "Datum završetak",
          btnBrojanje: "Početak od",
          lblSvake: "Svake ",
          lblSedmice: "sedmice",
        },
        dnevno: {
          title: "Dnevno",
          btnDnevno: "Dnevno",
          btnPocetak: "Početak",
          btnKraj: "Završetak",
          btnBrojanje: "Početak od",
          lblSvaki: "Svaki ",
          lblDan: "dan",
        },
        poPotrebi: {
          btnPoPotrebi: "Po potrebi",
        },
      },
      odgovorneOsobe: {
        title: "Odgovorne Osobe",
        label: "Odgovorne Osobe",
        placeholder: "Odaberite odgovorne osobe",
        name: "dedicatedUsers",
        error: "Nije odabrana odgovorna osoba",
      },
      tpEvidencije: {
        title: "Tacke Primjene",
        back: "Nazad",
        next: "Spasi promjene",
      },
      podsjetnik: {
        title: "Podsjetnik",
        label: "Podsjetnik",
        placeholder: "Dodaj podsjetnik",
        name: "notifications",
        btnTitle: "+ Dodaj podsjetnik",
      },
      poljeEvidencije: {
        title: "Vrsta Evidencije",
        label: "Vrsta Evidencije",
        placeholder: "Dodaj vrstu evidencije",
        name: "poljeEvidencije",
        error: "",
      },
      obavijesti: {
        title: "Obavijesti",
        cbNotifikacija: "Po isteku - notifikacija",
        plcPeriod: "Period",
        optPeriod: [
          {
            value: EnumTempDeterminer.MINUTA,
            label: "Minuta",
          },
          {
            value: EnumTempDeterminer.SATI,
            label: "Sati",
          },
          {
            value: EnumTempDeterminer.DANA,
            label: "Dana",
          },
        ],
        plcKada: "Prije/poslije",
        optKada: [
          {
            value: EnumAlarmType.beforeStart,
            label: "Prije početka",
          },
          {
            value: EnumAlarmType.afterStart,
            label: "Poslije početka",
          },
          {
            value: EnumAlarmType.beforeEnd,
            label: "Prije isteka",
          },
          {
            value: EnumAlarmType.afterEnd,
            label: "Poslije isteka",
          },
        ],
        plcKako: "Obavijest",
        optKako: [
          {
            value: EnumNotificationType.email1,
            label: "E-mail zaduženim osobama",
          },
          {
            value: EnumNotificationType.email2,
            label: "E-mail menadžerima",
          },
          {
            value: EnumNotificationType.email3,
            label: "E-mail šefovima",
          },
          {
            value: EnumNotificationType.sms1,
            label: "SMS zaduženim osobama",
          },
          {
            value: EnumNotificationType.sms2,
            label: "SMS menadžerima",
          },
          {
            value: EnumNotificationType.sms3,
            label: "SMS šefovima",
          },
          {
            value: EnumNotificationType.call1,
            label: "Poziv zaduženim osobama",
          },
          {
            value: EnumNotificationType.call2,
            label: "Poziv menadžerima",
          },
          {
            value: EnumNotificationType.call3,
            label: "Poziv šefovima",
          },
        ],
      },
    },
  };

export interface TaskTypes {
  recordTraceConfigId: number;
  category: string;
  title: string;
  description: string;
  status: boolean;
  revizija?: string;
}

export const tasks: TaskTypes[] = [];

interface InputField {
  label: string;
  placeholder: string;
  name: string;
  title?: string;
  error: string;
  noOptions?: string;
  description?: () => JSX.Element;
}

interface OptionEnumTempDeterminer {
  value: EnumTempDeterminer;
  label: string;
}

interface OptionEnumAlarmType {
  value: EnumAlarmType;
  label: string;
}

interface OptionEnumNotificationType {
  value: EnumNotificationType;
  label: string;
}

interface EvidencijaForm {
  title: string;
  branch: InputField;
  revizija: InputField;
  typeList: InputField;
  description: InputField;
  titleForm: InputField;
  category: InputField;
  buttonForward: string;
  buttonBack: string;
  successModal: SuccessModal;
  // Add other fields as needed
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface KreiranjeEvidencije {
  status: {
    activate: string;
    deactivate: string;
    saveAs: string;
  };
  revizija: InputField;
  description: InputField;
  nazivEvidencije: InputField;
  tackePrimjene: InputField;
  ucestalost: Ucestalost;
  odgovorneOsobe: InputField;
  tpEvidencije: {
    title: string;
    back: string;
    next: string;
  };
  podsjetnik: Podsjetnik;
  poljeEvidencije: InputField;
  obavijesti: Obavijesti;
  tempConfigPolja: ItempConfigPolja;
  errorCreation: string;
  errorMissingFields: string;
}

interface Ucestalost {
  title: string;
  label: string;
  placeholder: string;
  name: string;
  error: string;
  visegodisnje: Visegodisnje;
  godisnje: Godisnje;
  mjesecno: Mjesecno;
  sedmicno: Sedmicno;
  dnevno: Dnevno;
  poPotrebi: PoPotrebi;
}

interface Visegodisnje {
  title: string;
  btnVisegodisnje: string;
  btnDatum: string;
}

interface Godisnje {
  title: string;
  btnGodisnje: string;
  btnDatum: string;
}

interface Mjesecno {
  title: string;
  btnMjesecno: string;
  btnDatumPocetak: string;
  btnDatumKraj: string;
  btnBrojanje: string;
  lblSvaki: string;
  lblMjesec: string;
}

interface Sedmicno {
  title: string;
  btnSedmicno: string;
  btnDatumPocetak: string;
  btnDatumKraj: string;
  btnBrojanje: string;
  lblSvake: string;
  lblSedmice: string;
}

interface Dnevno {
  title: string;
  btnDnevno: string;
  btnPocetak: string;
  btnKraj: string;
  btnBrojanje: string;
  lblSvaki: string;
  lblDan: string;
}

interface PoPotrebi {
  btnPoPotrebi: string;
}

// Define similar interfaces for Godisnje, Mjesecno, Sedmicno, Dnevno, and PoPotrebi as needed based on your structure

interface Podsjetnik {
  title: string;
  label: string;
  placeholder: string;
  name: string;
  btnTitle: string;
}

interface Obavijesti {
  title: string;
  cbNotifikacija: string;
  plcPeriod: string;
  optPeriod: OptionEnumTempDeterminer[];
  plcKada: string;
  optKada: OptionEnumAlarmType[];
  plcKako: string;
  optKako: OptionEnumNotificationType[];
}

interface DeleteModal {
  title: string;
  text: string;
  buttonYes: string;
  buttonNo: string;
}

export interface KatalogEvidencijaResourcesBSInterface {
  addButton: string;
  evidencijePlaceholder: string;
  evidencijeOdabirPoslovnice: string;
  evidencijaForm: EvidencijaForm;
  kreiranjeEvidencije: KreiranjeEvidencije;
  deleteModal: DeleteModal;
  vrsteEvidencije: {
    template: string;
    evidencija: string;
  };
  typeEvidencije: {
    redovna: string;
    occasionally: string;
    placeholder: string;
  };
  errors: {
    poslovnica: string;
    katalogEvidencijaBranches: string;
    deleteError: string;
  };
  successModal: {
    title: string;
    body: string;
    buttonOk: string;
  };
  editDescription: {
    title: string;
  };
}

interface ItempConfigPolja {
  title: string;
  name: string;
  label: string;
  placeholder: string;
  dozvoljenaTempMin: {
    label: string;
    name: string;
    placeholder: string;
  };
  dozvoljenaTempMax: {
    label: string;
    name: string;
    placeholder: string;
  };
  dozvoljenoOdstupanje: {
    label: string;
    name: string;
    placeholder: string;
  };
}
