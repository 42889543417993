export const DashboardResourcesBS: DashboardResourcesInterface = {
  title: "Dnevni zadaci",
  kasniTitle: (counter: number) => (
    <h3 className="textColorMain">Kasni ({counter})</h3>
  ),
  danasTitle: (counter: number) => (
    <h3 className="textColorMain">Danas ({counter})</h3>
  ),
  uradjenoTitle: (counter: number) => (
    <h3 className="textColorMain">Urađeno ({counter})</h3>
  ),
  errorDone: "Greska tokom učitavanja. Molimo kontaktirajte administratora.",
  errorCards: "Greska tokom učitavanja. Molimo kontaktirajte administratora.",
  cardsPlaceholder: "Evidencije nisu pronadjene.",
};

export const DashboardResourcesEN: DashboardResourcesInterface = {
  title: "Dnevni zadaci EN",
  kasniTitle: (counter: number) => (
    <h3 className="textColorMain">Overdue ({counter})</h3>
  ),
  danasTitle: (counter: number) => (
    <h3 className="textColorMain">Today ({counter})</h3>
  ),
  uradjenoTitle: (counter: number) => (
    <h3 className="textColorMain">Done ({counter})</h3>
  ),
  errorDone: "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
  errorCards: "Greska tokom lodiranja. Molimo kontaktirajte administratora.",
  cardsPlaceholder: "Evidencije nisu pronadjene.",
};

export const DashboardResourcesDE: DashboardResourcesInterface = {
  title: "Dnevni zadaci DE",
  kasniTitle: (counter: number) => (
    <h3 className="textColorMain">Überfällig ({counter})</h3>
  ),
  danasTitle: (counter: number) => (
    <h3 className="textColorMain">Heute ({counter})</h3>
  ),
  uradjenoTitle: (counter: number) => (
    <h3 className="textColorMain">Fertig ({counter})</h3>
  ),
  errorDone: "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
  errorCards: "Greska tokom lodiranja. Molimo kontaktirajte administratora.",
  cardsPlaceholder: "Evidencije nisu pronadjene.",
};

export interface DashboardResourcesInterface {
  title: string;
  errorDone: string;
  errorCards: string;
  cardsPlaceholder: string;
  kasniTitle: (counter: number) => JSX.Element;
  danasTitle: (counter: number) => JSX.Element;
  uradjenoTitle: (counter: number) => JSX.Element;
}
