export const TackePrimjeneResourcesBS: TackePrimjeneResourcesBSInterface = {
  dupplicateError: "Dodavanje nije uspjelo, uneseni podatci su duplicirani",
  odabirKompanije: {
    title: "Tačke evidencije",
    dropdownLabel: "Odabir kompanije:",
    dropdownPlaceholder: "Odabir kompanije",
    dropdownNoOptionsMessage: "Nema unijetih kompanija",
    addCompanyButton: "Dodaj kompaniju",
    next: "Odaberi",
    back: "Nazad",
    errorLoading:
      "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
  },
  odabirPoslovnice: {
    title: "Tačke evidencije",
    dropdownLabel: "Odabir poslovnice:",
    dropdownPlaceholder: "Odabir poslovnice",
    dropdownNoOptionsMessage: "Nema unijetih poslovnica",
    addCompanyButton: "Dodaj poslovnicu",
    next: "Izmjeni",
    back: "Nazad",
    errorLoading:
      "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
  },
  izmjenaTP: {
    title: "Izmjena TP",
    recordpointtreesError: "Unesite Record Point JSON",
    recordpointtreesLabel: "Unesite tačke evidencije",
    loadTemplateButton: "Dodaj primjer",
    recordpointtreesName: "recordpointtrees",
    successModal: {
      title: "Unos podataka",
      body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
      buttonOk: "OK",
    },
    buttonTP: "Dodaj tacke evidencije",
    errorLoading:
      "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
    errorUpdate: "Greska tokom updatea. Molimo kontaktirajte administratora.",
  },
};

export interface TackePrimjeneResourcesBSInterface {
  dupplicateError: string;
  odabirKompanije: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    next: string;
    back: string;
    errorLoading: string;
  };
  odabirPoslovnice: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    next: string;
    back: string;
    errorLoading: string;
  };
  izmjenaTP: {
    title: string;
    recordpointtreesError: string;
    recordpointtreesLabel: string;
    loadTemplateButton: string;
    recordpointtreesName: string;
    successModal: {
      title: string;
      body: string;
      buttonOk: string;
    };
    buttonTP: string;
    errorLoading: string;
    errorUpdate: string;
  };
}

/*
Example json array: 
{
  "TackePrimjene": [
    {
      "NazivTacke": "Dvoriste",
      "Parent": "",
      "Order": "1"
    },
    {
      "NazivTacke": "Objekat",
      "Parent": "",
      "Order": "2"
    },
    {
      "NazivTacke": "Kancelarija",
      "Parent": "Objekat",
      "Order": "1"
    },
    {
      "NazivTacke": "WC",
      "Parent": "Objekat",
      "Order": "1"
    },
    {
      "NazivTacke": "Lavabo",
      "Parent": "WC",
      "Order": "1"
    },
    {
      "NazivTacke": "Cesma",
      "Parent": "Lavabo",
      "Order": "1"
    },
    {
      "NazivTacke": "Ogledalo",
      "Parent": "Lavabo",
      "Order": "2"
    }
  ]
}

*/
