import {
  DashboardResourcesBS,
  DashboardResourcesDE,
  DashboardResourcesEN,
} from "../pages/dashboard/Dashboard1/Dashboard.resources";
import {
  FooterResourcesBS,
  FooterResourcesDE,
  FooterResourcesEN,
} from "../layouts/Footer.resources";
import {
  MenuItemsResourcesBS,
  MenuItemsResourcesDE,
  MenuItemsResourcesEN,
} from "../layouts/TwoColumn/MenuItems.resources";

import { AboutUsBS } from "../pages/AboutUs/AboutUs.resources";
import { CIPO_V1ResourcesBS } from "../pages/Evidencije/CIPO_V1/CIPO_V1.resources";
import { CIPP_V1ResourcesBS } from "../pages/Evidencije/CIPP_V1/CIPP_V1.resources";
import { DERATIZACIJA_V1_ResourcesBS } from "../pages/Evidencije/DERATIZACIJA_V1/DERATIZACIJA_V1.resources";
import { DEZINFEKCIJA_V1_ResourcesBS } from "../pages/Evidencije/DEZINFEKCIJA_V1/DEZINFEKCIJA_V1.resources";
import { DEZINSEKCIJA_V1_ResourcesBS } from "../pages/Evidencije/DEZINSEKCIJA_V1/DEZINSEKCIJA_V1.resources";
import { DEZSR_V1ResourcesBS } from "../pages/Evidencije/DEZSR_V1/DEZSR_V1.resources";
import { DOBAVLJAC_V1ResourcesBS } from "../pages/Evidencije/DOBAVLJAC_V1/DOBAVLJAC_V1.resources";
import { DOSTAVA_V1ResourcesBS } from "../pages/Evidencije/DOSTAVA_V1/DOSTAVA_V1.resources";
import { DodavanjeKorisnikaResourcesBS } from "../pages/DodavanjeKorisnika/DodavanjeKorisnika/DodavanjeKorisnika.resources";
import { EDUZAP_V1ResourcesBS } from "../pages/Evidencije/EDUZAP_V1/EDUZAP_V1.resources";
import { EvidencijeResourcesBS } from "../pages/Evidencije/Evidencije.resources";
import { FirstLoginResourcesBS } from "../pages/FirstLogin/FirstLogin.resources";
import { GetQRResourcesBS } from "../pages/QR/GetQR/GetQR.resources";
import { KatalogEvidencijaPoPotrebiResourcesBS } from "../pages/EvidencijePoPotrebi/EvidencijePoPotrebi.resources";
import { KatalogEvidencijaResourcesBS } from "../pages/KatalogEvidencija/KatalogEvidencija.resources";
import LanguageDetector from "i18next-browser-languagedetector";
import { ListaEvidencijaResourcesBS } from "../pages/lists/ListaEvidencija/ListaEvidencija.resources";
import { ListaKompanijaResourcesBS } from "../pages/lists/ListaKompanija/ListaKompanija.resources";
import { ListaRadnogVremenaResourcesBS } from "../pages/lists/ListaRadnogVremena/ListaRadnogVremena.resources";
import { MOL_V1ResourcesBS } from "../pages/Evidencije/MOL_V1/MOL_V1.resources";
import { MyAccountResourcesBS } from "../pages/MyAccount/MyAccount.resources";
import { OPREMA_V1ResourcesBS } from "../pages/Evidencije/OPREMA_V1/OPREMA_V1.resources";
import { OTPAD_V1ResourcesBS } from "../pages/Evidencije/OTPAD_V1/OTPAD_V1.resources";
import { PRIJEMROBE_V1ResourcesBS } from "../pages/Evidencije/PRIJEMROBE_V1/PRIJEMROBE_V1.resources";
import { RadnoVrijemeEvdResourceBS } from "../pages/RadnoVrijemeEvd/RadnoVrijemeEvd.resources";
import { RadnoVrijemeSumEvdResourceBS } from "../pages/lists/RadnoVrijemeSumEvd/RadnoVrijemeSumEvd.resources";
import { RegistracijaKompanijeResourcesBS } from "../pages/RegistracijaKompanije/RegistracijaKompanije.resources";
import { SAN_V1ResourcesBS } from "../pages/Evidencije/SAN_V1/SAN_V1.resources";
import { SignatureCanvasResourcesBS } from "../components/SignatureCanvas/SignatureCanvas.resources";
import { TEMPERATURAKKTPROIZVODI_V1ResourcesBS } from "../pages/Evidencije/TEMPERATURAKKTPROIZVODI_V1/TEMPERATURAKKTPROIZVODI_V1.resources";
import { TEMPERATURAOGRANICENAKKT_V1ResourcesBS } from "../pages/Evidencije/TEMPERATURAOGRANICENAKKT_V1/TEMPERATURAOGRANICENAKKT_V1.resources";
import { TEMPERATURAOGRANICENA_V1ResourcesBS } from "../pages/Evidencije/TEMPERATURAOGRANICENA_V1/TEMPERATURAOGRANICENA_V1.resources";
import { TEMPERATURA_V1ResourcesBS } from "../pages/Evidencije/TEMPERATURA_V1/TEMPERATURA_V1.resources";
import { TackePrimjeneResourcesBS } from "../pages/TackePrimjene/TackePrimjene.resources";
import { UpravljanjeKorisnicimaResourcesBS } from "../pages/DodavanjeKorisnika/UpravljanjeKorisnicima/UpravljanjeKorisnicima.resources";
import { authBS } from "../pages/auth/Auth.resources";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  bs: {
    translation: {
      auth: authBS,
      registracijaKompanije: RegistracijaKompanijeResourcesBS,
      menuItems: MenuItemsResourcesBS,
      dodavanjeKorisnika: DodavanjeKorisnikaResourcesBS,
      katalogEvidencija: KatalogEvidencijaResourcesBS,
      evidencije: EvidencijeResourcesBS,
      dashboard: DashboardResourcesBS,
      CIPP_V1: CIPP_V1ResourcesBS,
      CIPO_V1: CIPO_V1ResourcesBS,
      firstLogin: FirstLoginResourcesBS,
      signatureCanvas: SignatureCanvasResourcesBS,
      evidencijePoPotrebi: KatalogEvidencijaPoPotrebiResourcesBS,
      listaKompanija: ListaKompanijaResourcesBS,
      listaEvidencija: ListaEvidencijaResourcesBS,
      dezinsekcija_v1: DEZINSEKCIJA_V1_ResourcesBS,
      deratizacija_v1: DERATIZACIJA_V1_ResourcesBS,
      dezinfekcija_v1: DEZINFEKCIJA_V1_ResourcesBS,
      dezsr_v1: DEZSR_V1ResourcesBS,
      dobavljac_v1: DOBAVLJAC_V1ResourcesBS,
      dostava_v1: DOSTAVA_V1ResourcesBS,
      eduzap_v1: EDUZAP_V1ResourcesBS,
      mol_v1: MOL_V1ResourcesBS,
      oprema_v1: OPREMA_V1ResourcesBS,
      otpad_v1: OTPAD_V1ResourcesBS,
      prijemRobe_v1: PRIJEMROBE_V1ResourcesBS,
      san_v1: SAN_V1ResourcesBS,
      temperatura_v1: TEMPERATURA_V1ResourcesBS,
      upravljanjeKorisnicima: UpravljanjeKorisnicimaResourcesBS,
      tackePrimjene: TackePrimjeneResourcesBS,
      dodavanjeKompanije: RegistracijaKompanijeResourcesBS,
      temperaturaogranicena_v1: TEMPERATURAOGRANICENA_V1ResourcesBS,
      temperaturaogranicenakkt_v1: TEMPERATURAOGRANICENAKKT_V1ResourcesBS,
      temperaturakktproizvodi_v1: TEMPERATURAKKTPROIZVODI_V1ResourcesBS,
      footer: FooterResourcesBS,
      aboutus: AboutUsBS,
      myaccount: MyAccountResourcesBS,
      radnoVrijeme: RadnoVrijemeEvdResourceBS,
      listaRadnoVrijeme: ListaRadnogVremenaResourcesBS,
      listaRadnoVrijemeSum: RadnoVrijemeSumEvdResourceBS,
      getQR: GetQRResourcesBS,
    },
  },
  de: {
    translation: {
      auth: authBS,
      registracijaKompanije: RegistracijaKompanijeResourcesBS,
      menuItems: MenuItemsResourcesDE,
      dodavanjeKorisnika: DodavanjeKorisnikaResourcesBS,
      katalogEvidencija: KatalogEvidencijaResourcesBS,
      evidencije: EvidencijeResourcesBS,
      dashboard: DashboardResourcesDE,
      CIPP_V1: CIPP_V1ResourcesBS,
      CIPO_V1: CIPO_V1ResourcesBS,
      firstLogin: FirstLoginResourcesBS,
      signatureCanvas: SignatureCanvasResourcesBS,
      evidencijePoPotrebi: KatalogEvidencijaPoPotrebiResourcesBS,
      listaKompanija: ListaKompanijaResourcesBS,
      listaEvidencija: ListaEvidencijaResourcesBS,
      dezinsekcija_v1: DEZINSEKCIJA_V1_ResourcesBS,
      deratizacija_v1: DERATIZACIJA_V1_ResourcesBS,
      dezinfekcija_v1: DEZINFEKCIJA_V1_ResourcesBS,
      dezsr_v1: DEZSR_V1ResourcesBS,
      dobavljac_v1: DOBAVLJAC_V1ResourcesBS,
      dostava_v1: DOSTAVA_V1ResourcesBS,
      eduzap_v1: EDUZAP_V1ResourcesBS,
      mol_v1: MOL_V1ResourcesBS,
      oprema_v1: OPREMA_V1ResourcesBS,
      otpad_v1: OTPAD_V1ResourcesBS,
      prijemRobe_v1: PRIJEMROBE_V1ResourcesBS,
      san_v1: SAN_V1ResourcesBS,
      temperatura_v1: TEMPERATURA_V1ResourcesBS,
      upravljanjeKorisnicima: UpravljanjeKorisnicimaResourcesBS,
      tackePrimjene: TackePrimjeneResourcesBS,
      dodavanjeKompanije: RegistracijaKompanijeResourcesBS,
      temperaturaogranicena_v1: TEMPERATURAOGRANICENA_V1ResourcesBS,
      temperaturaogranicenakkt_v1: TEMPERATURAOGRANICENAKKT_V1ResourcesBS,
      temperaturakktproizvodi_v1: TEMPERATURAKKTPROIZVODI_V1ResourcesBS,
      footer: FooterResourcesDE,
      aboutus: AboutUsBS,
      myaccount: MyAccountResourcesBS,
      radnoVrijeme: RadnoVrijemeEvdResourceBS,
      listaRadnoVrijeme: ListaRadnogVremenaResourcesBS,
      listaRadnoVrijemeSum: RadnoVrijemeSumEvdResourceBS,
      getQR: GetQRResourcesBS,
    },
  },
  en: {
    translation: {
      auth: authBS,
      registracijaKompanije: RegistracijaKompanijeResourcesBS,
      menuItems: MenuItemsResourcesEN,
      dodavanjeKorisnika: DodavanjeKorisnikaResourcesBS,
      katalogEvidencija: KatalogEvidencijaResourcesBS,
      evidencije: EvidencijeResourcesBS,
      dashboard: DashboardResourcesEN,
      CIPP_V1: CIPP_V1ResourcesBS,
      CIPO_V1: CIPO_V1ResourcesBS,
      firstLogin: FirstLoginResourcesBS,
      signatureCanvas: SignatureCanvasResourcesBS,
      evidencijePoPotrebi: KatalogEvidencijaPoPotrebiResourcesBS,
      listaKompanija: ListaKompanijaResourcesBS,
      listaEvidencija: ListaEvidencijaResourcesBS,
      dezinsekcija_v1: DEZINSEKCIJA_V1_ResourcesBS,
      deratizacija_v1: DERATIZACIJA_V1_ResourcesBS,
      dezinfekcija_v1: DEZINFEKCIJA_V1_ResourcesBS,
      dezsr_v1: DEZSR_V1ResourcesBS,
      dobavljac_v1: DOBAVLJAC_V1ResourcesBS,
      dostava_v1: DOSTAVA_V1ResourcesBS,
      eduzap_v1: EDUZAP_V1ResourcesBS,
      mol_v1: MOL_V1ResourcesBS,
      oprema_v1: OPREMA_V1ResourcesBS,
      otpad_v1: OTPAD_V1ResourcesBS,
      prijemRobe_v1: PRIJEMROBE_V1ResourcesBS,
      san_v1: SAN_V1ResourcesBS,
      temperatura_v1: TEMPERATURA_V1ResourcesBS,
      upravljanjeKorisnicima: UpravljanjeKorisnicimaResourcesBS,
      tackePrimjene: TackePrimjeneResourcesBS,
      dodavanjeKompanije: RegistracijaKompanijeResourcesBS,
      temperaturaogranicena_v1: TEMPERATURAOGRANICENA_V1ResourcesBS,
      temperaturaogranicenakkt_v1: TEMPERATURAOGRANICENAKKT_V1ResourcesBS,
      temperaturakktproizvodi_v1: TEMPERATURAKKTPROIZVODI_V1ResourcesBS,
      footer: FooterResourcesEN,
      aboutus: AboutUsBS,
      myaccount: MyAccountResourcesBS,
      radnoVrijeme: RadnoVrijemeEvdResourceBS,
      listaRadnoVrijeme: ListaRadnogVremenaResourcesBS,
      listaRadnoVrijemeSum: RadnoVrijemeSumEvdResourceBS,
      getQR: GetQRResourcesBS,
    },
  },
};

i18next
  // Binding our instance to an internal store
  .use(initReactI18next)
  // .use(Backend)
  // TODO: Language detector is not saving the cookies
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "bs",
    // Only en for example, without en-US or en-UK
    load: "languageOnly",
    // Allowed languages | how to get them in our components: i18n.options.supportedLngs
    // supportedLngs: ["bs", "en", "de"],
    supportedLngs: ["bs"],
    debug: true,

    interpolation: {
      escapeValue: false,
    },

    detection: {
      order: ["localStorage", "cookie", "navigator"],
      caches: ["localStorage", "cookie"],
      // Other detection options...
    },
  });

export default i18next;
