export const EDUZAP_V1ResourcesBS: EDUZAP_V1ResourcesBSInterface = {
  labelDone: "Datum obavljanja zadatka",
  errorMissingInput: "Molimo popunite sva polja",
  buttonEdu: "Popunite edukaciju",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  input: {
    edu: {
      pickTitle: "Dodajte novu edukaciju",
      label: "Edukacije",
      name: "education",
      placeholder: "Dodajte novu edukaciju",
    },
    signatureTitle: "Potpis",
    datum: {
      label: "Datum",
      placeholder: "Molimo unesite datum",
      name: "datum",
    },
    dokument: {
      label: "Dokument",
      placeholder: "Molimo unesite dokument",
      name: "dokument",
      description: () => (
        <>
          <h4> files here or click to upload.</h4>
          <span className="text-muted font-13">Only images, max 4MB</span>
        </>
      ),
    },
    cilj: {
      label: "Cilj edukacije",
      name: "cilj",
      placeholder: "Molimo unesite cilj edukacije",
    },
    izvrsilacObuke: {
      label: "Izvrsilac obuke",
      name: "izvrsilacObuke",
      placeholder: "Molimo unesite izvrsioca obuke",
    },
    radnoMjesto: {
      label: "Radno mjesto",
      name: "radnoMjesto",
      placeholder: "Molimo unesite radno mijesto",
    },
    mjestoObuke: {
      label: "Mjesto obuke",
      name: "mjestoObuke",
      placeholder: "Molimo unesite mjesto obuke",
    },
    tema: {
      label: "Tema",
      name: "tema",
      placeholder: "Molimo unesite temu",
    },
    verifikacijaEfikasnosti: {
      label: "Verifikacija efikasnosti",
      name: "verifikacijaEfikasnosti",
      placeholder: "Molimo unesite verifikaciju efikasnosti",
    },
    tipInput: {
      label: "Tip",
      name: "tip",
      placeholder: "Molimo unesite tip edukacije",
    },
    tip: {
      internal: "Interna edukacija",
      external: "Externa edukacija",
      unedefined: "Molimo odaberite tip edukacije",
    },
    verifikacijaEdukatoraPotpis: "Verifikacija - Potpis Edukatora",
    verifikacijaPrisustvaPotpis: "Verifikacija - Potpis Prisustva",
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
};

interface InputField {
  label: string;
  placeholder: string;
  name: string;
  description?: () => JSX.Element; // Optional, as not all fields have it
}

interface Tip {
  internal: string;
  external: string;
  unedefined: string; // There seems to be a typo here. Consider changing it to "undefined" or another appropriate key.
}

interface PickEdu {
  label: string;
  placeholder: string;
  name: string;
  description?: () => JSX.Element; // Optional, as not all fields have it
  pickTitle: string;
}

interface Input {
  edu: PickEdu;
  signatureTitle: string;
  datum: InputField;
  dokument: InputField;
  cilj: InputField;
  izvrsilacObuke: InputField;
  radnoMjesto: InputField;
  mjestoObuke: InputField;
  tema: InputField;
  verifikacijaEfikasnosti: InputField;
  tipInput: InputField;
  tip: Tip;
  verifikacijaEdukatoraPotpis: string;
  verifikacijaPrisustvaPotpis: string;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IErrors {
  registerPic: string;
}

export interface EDUZAP_V1ResourcesBSInterface {
  labelDone: string;
  errorMissingInput: string;
  input: Input;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  buttonEdu: string;
  errors: IErrors;
}
