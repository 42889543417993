export const UpravljanjeKorisnicimaResourcesBS: UpravljanjeKorisnicimaResourcesBSInterface =
  {
    dupplicateError: "Dodavanje nije uspjelo, uneseni podatci su duplicirani",
    upravljanjeKorisnicima: {
      title: "Upravljanje korisnicima",
      dropdownLabel: "Odabir korisnika:",
      dropdownPlaceholder: "Odaberite korisnika sa liste",
      dropdownNoOptionsMessage: "Nema podataka",
      addCompanyButton: "Dodaj korisnika",
      next: "Odaberi",
      back: "Nazad",
    },
    UKPPK: {
      title: "Upravljanje Korisnicima Provjer a Podataka Korisnika",
      addCompanyButton: "Dodaj kompaniju",
      addUserPosition: "Dodaj radno mjesto",
      addRecord: "Dodaj evidenciju",
      branch: "Poslovnica: ",
      address: "Adresa: ",
      position: "Radno mijesto: ",
    },
  };

export interface UpravljanjeKorisnicimaResourcesBSInterface {
  dupplicateError: string;
  upravljanjeKorisnicima: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    next: string;
    back: string;
  };
  UKPPK: {
    title: string;
    addCompanyButton: string;
    addUserPosition: string;
    addRecord: string;
    branch: string;
    address: string;
    position: string;
  };
}
