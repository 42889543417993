export const OTPAD_V1ResourcesBS: OTPAD_V1ResourcesBSInterface = {
  input: {
    datum: {
      name: "datum",
      label: "Datum obavljanja zadatka",
      placeholder: "Unesite datum",
    },
    vrstaOtpada: {
      name: "vrstaOtpada",
      label: "Unesite vrstu otpada",
      placeholder: "Unesite vrstu otpada",
    },
    jedinicaMjere: {
      name: "jedinicaMjere",
      label: "Unesite jedinicu mjere",
      placeholder: "Unesite jedinicu mjere",
    },
    primaoc: {
      name: "primaoc",
      label: "Unesite ime primaoca",
      placeholder: "Unesite ime primaoca",
    },
    adekvatnoPrikupljen: {
      name: "adekvatnoPrikupljen",
      label: "Odaberite da li je adekvatno prikupljen otpad",
      error: "Molimo odaberite da li je adekvatno prikupljen otpad",
      placeholder: "Molimo odaberite da li je adekvatno prikupljen otpad",
    },
    korektivnaMjera: {
      name: "korektivnaMjera",
      label: "Korektivna mjera",
      placeholder: "Unesite korektivnu mjeru",
    },
    kolicina: {
      name: "kolicina",
      label: "Kolicina",
      placeholder: "Unesite kolicinu",
    },
  },
  type: {
    EvdLabel: "Tip evidencije",
    Name: "type",
    Placeholder:
      "Upisuje se: Redovno, vanredno, generalno, sedmicno, mjesecno, godisnje...",
  },
  evidencijaForm: {
    buttonForward: "Potpisi",
    buttonBack: "Nazad",
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  txtPotvrda:
    "Odabirom opcije Potpiši potvrđujem da je zadatak izvršen prema uputstvu!",
  dropdownOptions: {
    da: "Da",
    ne: "Ne",
    undefined: "Molimo odaberite polje",
  },
};

interface InputField {
  name: string;
  label: string;
  placeholder?: string; // Optional since not all fields may have it, like 'adekvatnoPrikupljen'
  error?: string;
}

interface Input {
  datum: InputField;
  vrstaOtpada: InputField;
  jedinicaMjere: InputField;
  primaoc: InputField;
  adekvatnoPrikupljen: InputField;
  korektivnaMjera: InputField;
  kolicina: InputField;
}

interface Type {
  EvdLabel: string;
  Name: string;
  Placeholder: string;
}

interface EvidencijaForm {
  buttonForward: string;
  buttonBack: string;
}

interface SuccessModal {
  title: string;
  body: string;
  buttonOk: string;
}

interface IDropdownOptions {
  da: string;
  ne: string;
  undefined: string;
}

export interface OTPAD_V1ResourcesBSInterface {
  input: Input;
  type: Type;
  evidencijaForm: EvidencijaForm;
  successModal: SuccessModal;
  txtPotvrda: string;
  dropdownOptions: IDropdownOptions;
}
