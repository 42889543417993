export const DodavanjeKorisnikaResourcesBS: DodavanjeKorisnikaResourcesBSInterface =
  {
    dupplicateError: "Dodavanje nije uspjelo, uneseni podatci su duplicirani",
    odabirKompanije: {
      title: "Dodavanje korisnika",
      dropdownLabel: "Odabir kompanije:",
      dropdownPlaceholder: "Odabir kompanije",
      dropdownNoOptionsMessage: "Nema unijetih kompanija",
      addCompanyButton: "Dodaj kompaniju",
      next: "Odaberi",
      back: "Nazad",
      errorLoading:
        "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
    },
    odabirPoslovnice: {
      title: "Odabir poslovnice",
      dropdownLabel: "Odabir poslovnice:",
      dropdownPlaceholder: "Odabir poslovnice",
      dropdownNoOptionsMessage: "Nema unijetih poslovnica",
      addCompanyButton: "Dodaj poslovnicu",
      next: "Odaberi",
      back: "Nazad",
      errorLoading:
        "Greska tokom učitavanja. Molimo kontaktirajte administratora.",
    },
    dodavanjeKorisnika: {
      title: "Dodavanje korisnika",
      loadTemplateButton: "Dodaj primjer",
      addUsersError: "Unesite Listu Korisnika kao JSON",
      addUsersLabel: "Unos korisnika",
      addUsersName: "users",
      addUsersButton: "Dodaj korisnike",
      successModal: {
        title: "Unos podataka",
        body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
        buttonOk: "OK",
      },
      errorLoading:
        "Greska tokom loadiranja. Molimo kontaktirajte administratora.",
      errorUpdate: "Greska tokom updatea. Molimo kontaktirajte administratora.",
    },
  };

export interface DodavanjeKorisnikaResourcesBSInterface {
  dupplicateError: string;
  odabirKompanije: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    next: string;
    back: string;
    errorLoading: string;
  };
  odabirPoslovnice: {
    title: string;
    dropdownLabel: string;
    dropdownPlaceholder: string;
    dropdownNoOptionsMessage: string;
    addCompanyButton: string;
    next: string;
    back: string;
    errorLoading: string;
  };
  dodavanjeKorisnika: {
    title: string;
    loadTemplateButton: string;
    addUsersError: string;
    addUsersLabel: string;
    addUsersName: string;
    addUsersButton: string;
    successModal: {
      title: string;
      body: string;
      buttonOk: string;
    };
    errorLoading: string;
    errorUpdate: string;
  };
}

export default DodavanjeKorisnikaResourcesBS;

/*

{
  "item": [
    {
      "position": "Software Developer",
      "users": [
        {
          "email": "john.doe@example.com",
          "fullName": "John Doe",
          "password": "hashedpassword"
        },
        {
          "email": "jane.smith@example.com",
          "fullName": "Jane Smith",
          "password": "hashedpassword"
        }
      ]
    },
    {
      "position": "UX Designer",
      "users": [
        {
          "email": "alice.jones@example.com",
          "fullName": "Alice Jones",
          "password": "hashedpassword"
        }
      ]
    },
    {
      "position": "Project Manager",
      "users": [
        {
          "email": "bob.johnson@example.com",
          "fullName": "Bob Johnson",
          "password": "hashedpassword"
        },
        {
          "email": "emily.brown@example.com",
          "fullName": "Emily Brown",
          "password": "hashedpassword"
        }
      ]
    }
  ]
}


*/
